import { TableExporterType, TableFilterList, TableFilters, TableSort, UIModel } from '@aatdev/common-types';
import ReactDataGrid from '@aatdev/reactdatagrid';
import '@aatdev/reactdatagrid/index.css';
import { TypeColumn } from '@aatdev/reactdatagrid/types';
import { Split } from '@geoffcox/react-splitter';
import { CircularProgress, IconButton } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SvgIcon from '@material-ui/core/SvgIcon';
import {
    AddSharp as AddSharpIcon,
    Archive as ArchiveIcon,
    DeleteSharp as DeleteSharpIcon,
    EditSharp as EditSharpIcon,
    FileCopySharp as FileCopySharpIcon,
    SettingsEthernetSharp as SettingsEthernetSharpIcon,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { ToggleButton } from '@material-ui/lab';
import isDeepEqual from 'lodash.isequal';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as ExcelIcon } from '../../assets/excel.svg';
import { backend } from '../../data/Backend';
import { useTableData, useTableQuery, useTableState } from '../../data/UseQuery';
import LocalStoreService from '../../services/LocalStoreService';
import {
    doTableQueryThunk,
    loadEditingElement,
    saveElementThunk,
    updatePartEditingElementAction,
    updateTableVariableAction,
} from '../../store/actions/DataActions';
import { EditingElement, QueryState, QueryStateFields } from '../../store/actions/DataActionTypes';
import { MAIN_TAB_ID } from '../../utils/Constants';
import { downloadFile } from '../../utils/MiscUtils';
import { notify } from '../../utils/NotifierHelpers';
import { ReadOnlyContext } from '../../utils/ReadOnlyContext';
import { useEditableElement } from '../../utils/UseEditableElement';
import { deleteValidatedData } from '../../utils/ValidatedValueUtils';
import { getCustomAction } from '../Custom';
import ProgressDialog from '../Custom/Utils/ProgressDialog';
import DataFormTabs from '../DataEditor/DataForm/DataFormTabs';
import DepsDialog from '../Dialogs/DepsDialog';
import YesNoDialog from '../Dialogs/YesNoDialog';
import HelpButton from '../HelpWrapper/HelpButton';
import LoadIndicator from '../LoadIndicator/LoadIndicator';
import { renderActionButtons, renderActionCode } from '../Utils/ActionUtils';
import { getTranslations } from './CommonDataTableI18n';
import { CommonDataTableButtonsType } from './CommonDataTableTypes';
import { columnContextMenuPositions, getColumnsFromModel, getDefaultFilterValues } from './CommonDataTableUtils';
import CommonDataTableExportButton from './CommonDataTableExportButton';

type Props = {
    uiModel: UIModel;
    type: 'table' | 'select';
    uiModelSchemaName?: string;
    previewMode?: boolean;
    readonlyPreview?: boolean;
    className?: string;
    checkBoxColumn?: boolean;
    onDblClick?: (element: any) => void;
    refreshOnOpen?: boolean;
    tableButtons?: CommonDataTableButtonsType;
    toolbar?: React.ReactNode;
    filters?: TableFilters;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttons: {
            display: 'flex',
            width: '100%',
            marginBottom: '1em',
            marginLeft: '3em',
            paddingRight: '3em',
            '& button': {
                marginLeft: '1em',
            },
        },
        closeIcon: {
            position: 'absolute',
            top: '2.4rem',
        },
    }),
);

const minPaneSize = 300;

const CommonDataTable: FunctionComponent<Props> = (props: Props) => {
    const {
        uiModel,
        uiModelSchemaName = MAIN_TAB_ID,
        className,
        tableButtons,
        checkBoxColumn,
        onDblClick,
        type,
        refreshOnOpen = true,
        toolbar,
        previewMode,
        filters: propsFilters,
        readonlyPreview,
    } = props;
    const modelMeta = uiModel.meta;
    const modelSchema = uiModel.schema[uiModelSchemaName];
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();
    const queryState = useTableQuery(modelMeta.tableId);
    const tableState = useTableState(modelMeta.tableId);
    let tableData = useTableData(modelMeta.tableId);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [depsDialog, setDepsDialog] = useState(false);
    const [exportDialog, setExportDialog] = useState(false);
    const selectedItem = useEditableElement(tableState.selected);
    const navigate = useNavigate();
    const deps = selectedItem?.deps || [];
    const hasDeps = deps.length > 0;
    const deleteIfDeps = !hasDeps || (hasDeps && modelMeta.delete_if_deps);
    const containerRef = useRef<any>(null);

    useEffect(() => {
        if (!queryState.filters?.filters) {
            const filters = getDefaultFilterValues(modelSchema) as TableFilterList;
            propsFilters?.filters?.forEach((e) => {
                const found = filters.find((f) => f.name === e.name);
                if (found) {
                    found.type = e.type;
                    found.operator = e.operator;
                    found.value = e.value;
                } else {
                    filters.push(e);
                }
            });
            doQuery('filters', {
                filters,
                expression: propsFilters?.expression,
            });
        } else if (propsFilters?.expression && !isDeepEqual(propsFilters.expression, queryState.filters?.expression)) {
            doQuery('filters', {
                filters: queryState.filters.filters,
                expression: propsFilters.expression,
            });
        } else if (tableData.items.length === 0 || refreshOnOpen) {
            doQuery('updated', true);
        }
    }, [modelMeta, propsFilters?.expression]);

    useEffect(() => {
        if (tableState.selected) {
            dispatch(loadEditingElement(modelMeta.collection, tableState.selected));
        }
    }, [tableState.selected]);

    if (!tableState || !queryState) {
        return <CircularProgress />;
    }

    const handleDelete = async (yes: boolean) => {
        try {
            if (yes && tableState.selected) {
                tableButtons?.remove?.handler(tableState.selected, (success) => {});
            }
        } finally {
            setOpenDeleteDialog(false);
        }
    };

    const handleEdit = (type: 'create' | 'edit' | 'copy') => () => {
        const id = type === 'create' ? '' : tableState?.selected || '';
        if (type === 'create') {
            tableButtons?.create?.handler(id, (success) => {});
        } else if (type === 'edit') {
            tableButtons?.edit?.handler(id, (success) => {});
        } else {
            tableButtons?.copy?.handler(id, (success) => {});
        }
    };

    const doQuery = <T extends QueryStateFields>(field: T, data: QueryState[T]) => {
        dispatch(doTableQueryThunk(modelMeta, field, data));
    };

    const handleDataChange = (element: EditingElement, values: Record<string, any>) => {
        dispatch(updatePartEditingElementAction(element.id, values));
    };

    const handleClose = (element: EditingElement) => {
        dispatch(updateTableVariableAction(modelMeta, 'previewOpen', false));
    };

    const handleSave = (element: EditingElement) => {
        dispatch(saveElementThunk(element, navigate));
    };

    const handleExport = (columns: TypeColumn[], type: TableExporterType) => {
        setExportDialog(true);
        const fields = columns
            .filter((e) => e.defaultVisible)
            .map((e) => ({
                name: e.name,
                title: e.header,
                type: e.type,
            }));
        backend
            .exportTable(modelMeta.collection, queryState, fields, type, containerRef.current.clientWidth)
            .then((res) => {
                downloadFile(res.data, `${modelMeta.collection}.${type}`);
            })
            .catch((e) => {
                notify(dispatch, `Ошибка ${e}`, 'error');
            })
            .finally(() => setExportDialog(false));
    };

    const getPreview = () => {
        if (previewMode && tableState?.selected && selectedItem) {
            return (
                <div>
                    <IconButton
                        className={classes.closeIcon}
                        title={t('common:table.closePreview')}
                        onClick={() => dispatch(updateTableVariableAction(modelMeta, 'previewOpen', false))}>
                        <CloseIcon />
                    </IconButton>
                    <ReadOnlyContext.Provider value={!!readonlyPreview}>
                        <DataFormTabs
                            uiModel={uiModel}
                            element={selectedItem}
                            onChange={handleDataChange}
                            onClose={handleClose}
                            onSave={handleSave}
                        />
                    </ReadOnlyContext.Provider>
                </div>
            );
        }
        return null;
    };

    const columns = getColumnsFromModel(modelSchema, type, tableState.columnWidths).map((c: TypeColumn) => {
        if (c?.name) {
            const vState = tableState?.columns_visibility?.[c?.name];
            if (vState !== undefined) {
                return {
                    ...c,
                    defaultVisible: vState,
                };
            }
        }
        return c;
    });

    const savedColumnOrder = tableState.columnOrder || [];
    const columnOrder = [
        ...savedColumnOrder,
        ...columns
            .filter((e) => e.name !== undefined && savedColumnOrder.findIndex((c) => c === e.name) === -1)
            .map((e) => e.name || ''),
    ];

    const styleRowFunc = modelSchema.row_style_func ? new Function('row', modelSchema.row_style_func) : undefined;

    const getDataTable = () => {
        return (
            <ReactDataGrid
                className={className}
                idProperty={'_id'}
                checkboxColumn={checkBoxColumn === undefined || checkBoxColumn}
                multiSelect={false}
                shareSpaceOnResize={true}
                columns={columns}
                dataSource={() => {
                    return Promise.resolve({ data, count });
                }}
                pagination
                limit={queryState?.pageSize}
                skip={queryState?.skip}
                remotePagination={true}
                remoteSort={true}
                remoteFilter={true}
                filterValue={queryState?.filters?.filters || getDefaultFilterValues(modelSchema)}
                sortInfo={queryState?.sort as any}
                defaultSelected={tableState?.selected}
                columnOrder={columnOrder}
                rowStyle={(args) => {
                    const styles: any = {};
                    if (styleRowFunc) {
                        Object.assign(styles, styleRowFunc(args.data));
                    }
                    if (args.data.archived) {
                        styles['background-color'] = '#fff3e0';
                    }
                    return styles;
                }}
                onSelectionChange={(event) => {
                    if (typeof event.selected === 'string') {
                        dispatch(updateTableVariableAction(modelMeta, 'selected', event?.selected));
                    } else {
                        dispatch(updateTableVariableAction(modelMeta, 'selected', undefined));
                    }
                }}
                onSortInfoChange={(sortInfo) => {
                    doQuery('sort', sortInfo as TableSort);
                }}
                onFilterValueChange={(value) => {
                    doQuery('filters', {
                        filters: value as TableFilterList,
                        expression: queryState?.filters?.expression,
                    });
                }}
                onLimitChange={(limit) => doQuery('pageSize', limit)}
                onSkipChange={(skip) => doQuery('skip', skip)}
                onCellClick={(event, cellProps) => {
                    if (event.detail === 2) {
                        if (onDblClick) {
                            onDblClick(cellProps.data);
                        } else {
                            handleEdit('edit')();
                        }
                    }
                }}
                onRefresh={() => {
                    doQuery('sort', queryState?.sort);
                }}
                columnContextMenuPosition={'fixed'}
                columnContextMenuAlignPositions={columnContextMenuPositions}
                columnFilterContextMenuAlignPositions={columnContextMenuPositions}
                onColumnVisibleChange={({ column, visible }) => {
                    if (column?.name) {
                        dispatch(
                            updateTableVariableAction(modelMeta, 'columns_visibility', {
                                ...(tableState.columns_visibility || {}),
                                [column.name]: visible,
                            }),
                        );
                    }
                }}
                onColumnOrderChange={(columnOrder) => {
                    dispatch(updateTableVariableAction(modelMeta, 'columnOrder', columnOrder));
                }}
                onColumnResize={({ column, width, flex }) => {
                    if (typeof column.name === 'string' && flex) {
                        dispatch(
                            updateTableVariableAction(modelMeta, 'columnWidths', {
                                [column.name]: flex,
                            }),
                        );
                    }
                }}
                i18n={getTranslations()}
            />
        );
    };

    const getContent = () => {
        const enabled = previewMode && tableState?.selected && tableState?.previewOpen;
        if (enabled) {
            const initialSize = tableState.tableWidth || LocalStoreService.getAsNumber('table-area-width', 0);
            return (
                <Split
                    key={uiModel.meta.tableId}
                    minPrimarySize={`${minPaneSize}px`}
                    minSecondarySize={`${minPaneSize}px`}
                    resetOnDoubleClick={true}
                    initialPrimarySize={initialSize > 0 ? `${initialSize}px` : '70%'}
                    splitterSize={'3px'}
                    onMeasuredSizesChanged={(sizes) => {
                        LocalStoreService.set('table-area-width', sizes.primary);
                        dispatch(updateTableVariableAction(modelMeta, 'tableWidth', sizes.primary));
                    }}>
                    {getDataTable()}
                    {getPreview()}
                </Split>
            );
        } else {
            return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {getDataTable()}
                    <div>
                        <IconButton
                            title={t('common:table.openPreview')}
                            onClick={() => dispatch(updateTableVariableAction(modelMeta, 'previewOpen', true))}>
                            <MenuOpenIcon />
                        </IconButton>
                    </div>
                </div>
            );
        }
    };

    const data = tableData.items || [];
    const count = tableData.total || 0;

    if (tableData.error) {
        return <p>{tableData.error}</p>;
    }

    if (tableData.loading) {
        return <LoadIndicator open={tableData.loading} />;
    }

    const actions = Array.isArray(modelSchema.actions)
        ? modelSchema.actions
              .filter((e) => !!getCustomAction(e.action))
              .map((e) =>
                  getCustomAction(e.action)(
                      e,
                      deleteValidatedData(selectedItem || {}),
                      modelSchema,
                      tableData.permissions,
                  ),
              )
        : [];

    const getButtons = () => {
        if (type === 'table') {
            return (
                <>
                    {renderActionButtons(actions, 'left')}
                    <div style={{ flex: 1 }} />
                    {renderActionButtons(actions, 'right')}
                    <ToggleButton
                        selected={queryState.showArchived}
                        onChange={() => {
                            dispatch(doTableQueryThunk(modelMeta, 'showArchived', !queryState.showArchived));
                        }}
                        disabled={!tableData.permissions?.read}
                        title={t('common:buttons.archive')}>
                        <ArchiveIcon color={'primary'} />
                    </ToggleButton>
                    <IconButton
                        color={'primary'}
                        title={t('common:buttons.deps')}
                        disabled={!tableState?.selected || deps.length === 0}
                        onClick={() => setDepsDialog(true)}>
                        <SettingsEthernetSharpIcon />
                    </IconButton>
                    <CommonDataTableExportButton
                        disabled={type !== 'table' || !modelSchema.exportable}
                        onExport={(type) => handleExport(columns, type)}
                    />
                    <IconButton
                        color={'primary'}
                        onClick={handleEdit('copy')}
                        disabled={
                            !tableState?.selected ||
                            !tableButtons?.copy ||
                            tableButtons?.copy?.hidden ||
                            !tableData.permissions?.create ||
                            type !== 'table' ||
                            !uiModel.meta.copyable
                        }
                        title={tableButtons?.copy?.label}>
                        <FileCopySharpIcon />
                    </IconButton>
                    <IconButton
                        color={'primary'}
                        onClick={handleEdit('create')}
                        disabled={
                            !tableButtons?.create ||
                            tableButtons?.create?.hidden ||
                            (!tableData.permissions?.create && !tableButtons?.create?.enabled)
                        }
                        title={tableButtons?.create?.label}>
                        <AddSharpIcon />
                    </IconButton>
                    <IconButton
                        color={'primary'}
                        onClick={handleEdit('edit')}
                        disabled={
                            !tableState?.selected ||
                            !tableButtons?.edit ||
                            tableButtons.edit?.hidden ||
                            (!tableData.permissions?.edit && !tableButtons?.edit?.enabled)
                        }
                        title={tableButtons?.edit?.label}>
                        <EditSharpIcon />
                    </IconButton>
                    <IconButton
                        color={'secondary'}
                        onClick={() => setOpenDeleteDialog(true)}
                        style={{ marginRight: '1em' }}
                        disabled={
                            !tableState?.selected ||
                            !tableButtons?.remove ||
                            tableButtons.remove?.hidden ||
                            !deleteIfDeps ||
                            (!tableData.permissions?.delete && !tableButtons?.remove?.enabled)
                        }
                        title={tableButtons?.remove?.label}>
                        <DeleteSharpIcon />
                    </IconButton>
                    <HelpButton type={'window'} helpId={[modelMeta.collection]} />
                </>
            );
        }
        return null;
    };

    return (
        <div ref={containerRef}>
            {renderActionCode(actions)}
            <YesNoDialog
                open={openDeleteDialog}
                onClose={handleDelete}
                title={t('dialogs:delete.title')}
                content={t('dialogs:delete.content', { item: selectedItem?.data?.name?.value })}
                deps={deps}
            />
            <DepsDialog open={depsDialog} onClose={() => setDepsDialog(false)} deps={deps} />
            <ProgressDialog open={exportDialog} title={t('dialogs:export.title')} />
            <div className={classes.buttons}>
                <div style={{ alignSelf: 'center' }}>{toolbar ? toolbar : null}</div>
                {getButtons()}
            </div>
            {getContent()}
        </div>
    );
};

export default CommonDataTable;
