import { DBElementPermissions, UIModelAction, UIModelSchema } from '@aatdev/common-types';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Readable } from 'stream';
import { backend } from '../../../../data/Backend';
import { BaseDbCollection } from '../../../../data/BaseDbCollection';
import { openFormEditorThunk } from '../../../../store/actions/DataActions';
import { EditingElement } from '../../../../store/actions/DataActionTypes';
import { clearStateVariable, setStateVariable } from '../../../../store/actions/GeneralActions';
import { AppState } from '../../../../store/StoreTypes';
import { notify } from '../../../../utils/NotifierHelpers';
import { CustomTableActionType } from '../../../CommonDataTable/CommonDataTableTypes';
import { actionUtils } from '../../../Utils/ActionUtils';
import { getCollectionItems, StringWritable } from './Utils';

const unzip = require('unzip-stream');

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            minWidth: 300,
            minHeight: 400,
            backgroundColor: theme.palette.background.paper,
        },
        dialog: {
            minWidth: 400,
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
        },
        mode: {
            minWidth: 300,
            padding: 10,
        },
    }),
);

const action = (
    action: UIModelAction,
    element: EditingElement,
    schema: UIModelSchema,
    permissions: DBElementPermissions,
): CustomTableActionType => {
    return {
        buttonPosition(): 'left' | 'right' {
            return 'right';
        },
        renderButton: () => {
            return () => {
                const dispatch = useDispatch();
                useEffect(() => {
                    return () => {
                        dispatch(clearStateVariable(action.action));
                    };
                }, [element]);
                return (
                    <IconButton
                        color={'primary'}
                        onClick={() => dispatch(setStateVariable(action.action, true))}
                        disabled={!permissions?.create || !action.enabled_for_permissions.find((e) => e === 'create')}
                        title={action.title}>
                        {actionUtils(action.icon)}
                    </IconButton>
                );
            };
        },
        renderCode: () => {
            return () => {
                const classes = useStyles();
                const dispatch = useDispatch();
                const { t } = useTranslation();
                const navigate = useNavigate();
                const [selected, setSelected] = useState<string[]>([]);
                const [available, setAvailable] = useState<string[]>([]);
                const [file, setFile] = useState<string>('');
                const open = useSelector((state: AppState) => !!state?.general?.variables?.[action.action]);
                const collections = getCollectionItems();

                useEffect(() => {
                    setSelected(collections.map((e) => e.name));
                }, [element.data]);

                const handleSelect = (item: any) => () => {
                    const currentIndex = selected.indexOf(item.name);
                    const newChecked = [...selected];

                    if (currentIndex === -1) {
                        newChecked.push(item.name);
                    } else {
                        newChecked.splice(currentIndex, 1);
                    }
                    setSelected(newChecked);
                };

                const onFileChange = (event: any) => {
                    const file = event.target.files[0];
                    if (file) {
                        setFile(file);
                        const fileReader = new FileReader();
                        fileReader.onloadend = (e) => {
                            if (fileReader.result) {
                                const content = new Uint8Array(fileReader.result as ArrayBuffer);
                                const readable = new Readable();
                                readable.push(content);
                                readable.push(null);
                                readable.pipe(unzip.Parse()).on('entry', function (entry: any) {
                                    const filePath = entry.path;
                                    if (filePath === 'project.json') {
                                        entry.pipe(
                                            new StringWritable((json) => {
                                                setAvailable(Object.keys(json.collections));
                                            }),
                                        );
                                    }
                                });
                            }
                        };
                        fileReader.readAsArrayBuffer(file);
                    }
                };

                const handleClose = () => {
                    dispatch(clearStateVariable(action.action));
                };

                const handleConfirm = async () => {
                    try {
                        const res = await backend.importProject(element.data._id, selected, file);
                        dispatch(openFormEditorThunk(BaseDbCollection.project, res.data.element._id, navigate, false));
                    } catch (e) {
                        console.log(e);
                        notify(dispatch, `Ошибка ${e}`, 'error');
                    } finally {
                        handleClose();
                    }
                };

                const renderItem = (item: any) => (
                    <ListItem key={item.name} role={undefined} dense button onClick={handleSelect(item)}>
                        <ListItemIcon>
                            <Checkbox
                                edge='start'
                                checked={selected.indexOf(item.name) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                            <ListItemText id={item.name} primary={item.label} />
                        </ListItemIcon>
                    </ListItem>
                );

                return (
                    <Dialog className={classes.dialog} open={open} onClose={handleClose}>
                        <DialogTitle>{t('tables:project.actions.import.title')}</DialogTitle>
                        <DialogContent className={classes.content}>
                            <List className={classes.root}>
                                {collections
                                    .filter((c) => available.findIndex((a) => a === c.name) !== -1)
                                    .map((e) => renderItem(e))}
                            </List>
                            <Button variant='contained' component='label'>
                                {t('tables:project.actions.import.buttons.choose_file')}
                                <input type='file' accept='.zip' style={{ display: 'none' }} onChange={onFileChange} />
                            </Button>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color='secondary' autoFocus>
                                {t('tables:project.actions.import.buttons.cancel')}
                            </Button>
                            <Button onClick={handleConfirm} color='primary' disabled={selected.length === 0}>
                                {t('tables:project.actions.import.buttons.import')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                );
            };
        },
    };
};

export default action;
