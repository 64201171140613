import { DBElementDeps } from '@aatdev/common-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DepsDialog from './DepsDialog';

type Props = {
    open: boolean;
    onClose: (yes: boolean) => void;
    title: string;
    content: string;
    deps?: DBElementDeps;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        minWidth: {
            minWidth: 400,
        },
        depsButton: {
            textAlign: 'center',
            marginTop: '1em',
        },
    }),
);

const YesNoDialog: FunctionComponent<Props> = (props: Props) => {
    const { deps = [] } = props;
    const styles = useStyles();
    const { t } = useTranslation();
    const [depsDialog, setDepsDialog] = useState(false);
    const [ignoreDeps, setIgnoreDeps] = useState(deps.length === 0);

    useEffect(() => {
        setIgnoreDeps(deps.length === 0);
    }, [deps]);

    return (
        <>
            <DepsDialog open={depsDialog} onClose={() => setDepsDialog(false)} deps={deps} />
            <Dialog open={props.open} onClose={() => props.onClose(false)}>
                <DialogTitle style={{ cursor: 'move' }}>{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={styles.minWidth}>
                        <div>{props.content}</div>
                        <div className={styles.depsButton}>
                            {deps.length > 0 && (
                                <Button variant={'outlined'} onClick={() => setDepsDialog(true)}>
                                    {t('dialogs:yesno.buttons.show_deps')}
                                </Button>
                            )}
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.onClose(false)} color='primary'>
                        {t('common:buttons.no')}
                    </Button>
                    {deps.length > 0 && (
                        <Button disabled={ignoreDeps} onClick={() => setIgnoreDeps(true)} color='secondary'>
                            {t('dialogs:yesno.buttons.delete_with_deps')}
                        </Button>
                    )}
                    <Button onClick={() => props.onClose(true)} color='secondary' disabled={!ignoreDeps}>
                        {t('common:buttons.yes')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default YesNoDialog;
