import UploadListStatistics from './CallingList/UploadListStatistics';

const componentsMap: any = {};
const actionsMap: any = {};

registerComponent('StrategyPhoneRules', require('./Strategy/StrategyPhoneRules').default);
registerComponent('CallingListStructure', require('./CallingList/CallingListStructure').default);
registerComponent('CallingListSources', require('./CallingList/CallingListSources').default);
registerComponent('UploadedList', require('./CallingList/UploadedList').default);
registerComponent('UploadListStatistics', require('./CallingList/UploadListStatistics').default);
registerComponent('UploadListNormalizeRules', require('./CallingList/UploadListNormalizeRules').default);
registerComponent('SelectionRules', require('./Selection/SelectionRulesV2').default);
registerComponent('SelectionPhoneList', require('./Selection/SelectionPhoneList').default);
registerComponent('CompaignSchedule', require('./Campaign/CampaignSchedule').default);
registerComponent('CompaignPhoneList', require('./Campaign/CampaignPhoneList').default);
registerComponent('CampaignStat', require('./Campaign/CampaignStat').default);
registerComponent('CompaignLimits', require('./Campaign/CampaignLimits').default);
registerComponent('WorkCalendar', require('./WorkCalendar/WorkCalendar').default);
registerComponent('TrunkStatusField', require('./Trunk/TrunkStatusField').default);
registerComponent('CallReport', require('./Report/CallReport').default);
registerComponent('ContactReport', require('./Report/ContactReport').default);
registerComponent('Dashboard', require('./Dashboard/Dashboard').default);

registerAction('project.import', require('./Campaign/Actions/ImportAction').default);
registerAction('project.export', require('./Campaign/Actions/ExportAction').default);
registerAction('project.player', require('./Campaign/Actions/PlayerAction').default);
registerAction('uploaded_list.clear_status', require('./CallingList/Actions/ClearAction').default);

function registerComponent(id: string, component: any) {
    componentsMap[id] = component;
}

function registerAction(id: string, action: any) {
    actionsMap[id] = action;
}

export const getCustomComponent = (name: string) => {
    return componentsMap[name];
};

export const getCustomAction = (name: string) => {
    return actionsMap[name];
};
