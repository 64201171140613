import { UIModel } from '@aatdev/common-types';
import { FunctionComponent } from 'react';
import { withUIModel } from '../../utils/WithUIModel';
import DataFormEditor from './DataFormEditor';
import DataTableEditor from './DataTableEditor';

type Props = {
    uiModel: UIModel;
};

const DataEditor: FunctionComponent<Props> = (props: Props) => {
    const { uiModel } = props;
    if (uiModel.meta.type === 'form') {
        return <DataFormEditor {...props} />;
    } else {
        return <DataTableEditor {...props} />;
    }
};

export default withUIModel(DataEditor);
