import { DBElementDeps } from '@aatdev/common-types';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { openFormEditorThunk } from '../../store/actions/DataActions';
import DepsTree from '../Utils/DepsTree';

type Props = {
    open: boolean;
    onClose: () => void;
    deps: DBElementDeps;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        minWidth: {
            minWidth: 500,
            height: 500,
        },
    }),
);

const DepsDialog: FunctionComponent<Props> = (props: Props) => {
    const { deps, onClose } = props;
    const styles = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleOpen = (collection: string, id: string) => {
        dispatch(openFormEditorThunk(collection, id, navigate, false));
        onClose();
    };

    return (
        <Dialog open={props.open} onClose={() => props.onClose()}>
            <DialogTitle style={{ cursor: 'move' }}>{t('dialogs:deps.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText className={styles.minWidth}>
                    {deps.length > 0 ? (
                        <DepsTree deps={deps} onOpenElement={handleOpen} />
                    ) : (
                        <span>{t('dialogs:deps.messages.no_deps')}</span>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose()} color='secondary'>
                    {t('common:buttons.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DepsDialog;
