import { SearchResult, UIFieldConditions } from '@aatdev/common-types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { backend } from '../../data/Backend';
import { notify } from '../../utils/NotifierHelpers';
import InfiniteAutocomplete, { AutocompleteOption } from '../InfiniteAutocomplete/InfiniteAutocomplete';

type Props = {
    value: AutocompleteOption | null;
    entityType: string;
    entityConditions?: UIFieldConditions;
    onChange: (option: AutocompleteOption | AutocompleteOption[] | null) => void;
    variant?: 'standard' | 'outlined' | 'filled';
    label?: string;
    error?: boolean;
    helperText?: string;
    filterOp?: string;
    placeholder?: string;
    disabled?: boolean;
    multiple?: boolean;
    helpId?: string[];
};

const pageSize = 30;

const EntityAutocomplete: FunctionComponent<Props> = (props: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const [limit, setLimit] = useState(pageSize);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<SearchResult | undefined>(undefined);
    const [showArchived, setShowArchived] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (open) {
            const query: any = {
                filters: {
                    filters: [
                        { type: 'string', name: 'name', operator: props.filterOp || 'startsWith', value: searchValue },
                    ],
                },
                skip: 0,
                pageSize: limit,
                showArchived: showArchived,
            };
            if (props.entityConditions) {
                for (const key of Object.keys(props.entityConditions)) {
                    const val = props.entityConditions[key];
                    if (typeof val === 'boolean') {
                        if (val) {
                            query.filters.filters.push({
                                type: 'boolean',
                                name: key,
                                operator: 'eq',
                                value: true,
                            });
                        } else {
                            query.filters.filters.push({
                                type: 'boolean',
                                name: key,
                                operator: 'eq',
                                value: null,
                            });
                        }
                    } else {
                        query.filters.filters.push({
                            type: 'string',
                            name: key,
                            operator: 'eq',
                            value: props.entityConditions[key],
                        });
                    }
                }
            }
            setLoading(true);
            backend
                .tableQuery(props.entityType, -1, query, 'select')
                .then((res) => {
                    setData(res);
                })
                .catch((reason) => {
                    notify(dispatch, reason, 'error');
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [props.entityType, props.entityConditions, props.filterOp, searchValue, limit, open, showArchived]);

    const loadNextPage = (next: number) => {
        if (next < pageSize) {
            next = pageSize;
        }
        setLimit(limit + next);
    };

    const handleOnInput = (text: string) => {
        setSearchValue(text);
    };

    return (
        <InfiniteAutocomplete
            label={props.label}
            error={props.error}
            helperText={props.helperText}
            noOptionsText={t('common:autocomplete.noOptionsText')}
            size={'small'}
            variant={props.variant || 'standard'}
            placeholder={props.placeholder || ''}
            hasNextPage={data?.hasNext || true}
            isNextPageLoading={loading}
            items={data?.items || []}
            total={data?.total || 0}
            loadNextPage={loadNextPage}
            onSelect={(option) => props.onChange(option)}
            inputValue={searchValue}
            onInput={(text) => handleOnInput(text)}
            disableFilterOptions={true}
            value={props.value}
            disabled={props.disabled || false}
            multiple={props.multiple}
            onOpen={setOpen}
            helpId={props.helpId}
            showArchived={showArchived}
            onShowArchivedChanged={() => setShowArchived(!showArchived)}
        />
    );
};

export default EntityAutocomplete;
