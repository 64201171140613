import { LoggedUser } from '@aatdev/common-types';
import { Avatar, Button, Container, CssBaseline, makeStyles, TextField, Typography } from '@material-ui/core';
import { LockOutlined } from '@material-ui/icons';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { backend } from '../../data/Backend';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

type Props = {
    onLogin: (user?: LoggedUser) => void;
};

const LoginForm: FunctionComponent<Props> = (props: Props) => {
    const { onLogin } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = () => {
        backend
            .login(login, password)
            .then((res) => {
                if (!res.data?.user) {
                    setError(res.data.message);
                }
                onLogin(res.data?.user);
            })
            .catch((e) => {
                onLogin(undefined);
                setError(e.toString());
            });
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlined />
                </Avatar>
                <Typography component='h1' variant='h5'>
                    {t('dialogs:login.title')}
                </Typography>
                <form
                    className={classes.form}
                    noValidate
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='login'
                        label={t('dialogs:login.fields.login')}
                        name='login'
                        autoComplete='login'
                        autoFocus
                        value={login}
                        onChange={(event) => setLogin(event.target.value)}
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label={t('dialogs:login.fields.password')}
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                    <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        onClick={handleLogin}>
                        {t('dialogs:login.buttons.login')}
                    </Button>
                </form>
                <div style={{ color: 'red' }}>{t(error)}</div>
            </div>
        </Container>
    );
};

export default LoginForm;
