import { DialerSelectionModel } from '@aatdev/dialer-types';
import { FunctionComponent } from 'react';
import { deleteValidatedData } from '../../../utils/ValidatedValueUtils';
import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import SelectionPhoneListChain from '../Tables/Chains/SelectionPhoneListChain';

type Props = {} & DataFormProps;

const SelectionPhoneList: FunctionComponent<Props> = (props) => {
    const selection: DialerSelectionModel = deleteValidatedData(props.element.data);
    if (!selection?._id) {
        return <span />;
    }
    return (
        <SelectionPhoneListChain
            selection={selection}
            prefixTableId={props.element.data._id.value}
            key={selection._id}
        />
    );
};

export default SelectionPhoneList;
