import validate from 'validate.js';

export type PredefinedConstrainTypes = keyof typeof predefinedConstrains;

const predefinedConstrains = {
    greaterThan0: {
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            notGreaterThan: 'positive_gt_0',
        },
        presence: {
            message: 'required',
            allowEmpty: false,
        },
    },
    notEmpty: {
        presence: {
            message: 'required',
            allowEmpty: false,
        },
    },
};

export const getConstrain = (config: PredefinedConstrainTypes | any) => {
    if (config) {
        if ((predefinedConstrains as any)[config]) {
            return (predefinedConstrains as any)[config];
        }
        return config;
    }
    return undefined;
};

export const validateAgainstConstraint = (value: any, constrain: any) => {
    if (!!constrain) {
        const messages = validate({ data: value }, { data: constrain })?.data as string[];
        return {
            messages: (messages || []).map((e: string) => e.substr(5)),
            valid: !messages || messages.length === 0,
        };
    }
    return {
        messages: [],
        valid: true,
    };
};
