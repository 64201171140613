import { UIModel } from '@aatdev/common-types';
import React from 'react';
import { useSelector } from 'react-redux';
import NotFound from '../components/NotFound/NotFound';
import { AppState } from '../store/StoreTypes';
import { useCurrentMenuItem } from './UseCurrentMenuItem';

export type WithUIModelProps = {
    uiModel: UIModel;
};

export const withUIModel = (Component: any) => {
    return (props: any) => {
        const menuState = useCurrentMenuItem();
        const models = useSelector((state: AppState) => state.db.loadedModels);
        const model = menuState?.uiModel;

        if (!model || !models[model.meta.collection]) {
            return <NotFound>Model not found "{model?.meta?.collection}"</NotFound>;
        }
        return (
            <Component
                uiModel={{
                    ...model,
                }}
                {...props}
            />
        );
    };
};
