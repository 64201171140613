import { DBElement, SearchResult, UIModelMeta } from '@aatdev/common-types';
import { NavigateFunction } from 'react-router';
import { Dispatch } from 'redux';
import { v4 } from 'uuid';
import { backend } from '../../data/Backend';
import { updateDatabase } from '../../data/BackendUtils';
import { BaseDbCollection } from '../../data/BaseDbCollection';
import { ProjectModel } from '../../data/models/ProjectModel';
import MQClient from '../../data/MQClient';
import { fixedT } from '../../i18n';
import { createSubMenuConfig, getRoutePath } from '../../utils/MenuUtils';
import { getTimestamp } from '../../utils/MiscUtils';
import { updateInnerField } from '../../utils/ModelUtils';
import { notify } from '../../utils/NotifierHelpers';
import { BlockNavigateFunction } from '../../utils/UseBlockNavigate';
import { addValidatedData, createValidateValue } from '../../utils/ValidatedValueUtils';
import { AppState, APThunkResult } from '../StoreTypes';
import {
    BlockedNavigateData,
    DataActionType,
    DataThunkCallback,
    EditingElement,
    LoadModelsResponse,
    QueryState,
    QueryStateFields,
    TableChainFields,
    TableChainState,
    TableMenuItem,
    TableState,
    TableStateFields,
    TableSubMenuItem,
} from './DataActionTypes';

/**
 * load models and translate titles
 */
export const loadModelsAction = (projectId: string): APThunkResult => {
    const t = fixedT();
    return (dispatch: Dispatch, getState): void => {
        backend
            .getUIModels(projectId)
            .then((res) => {
                const data = res.data as LoadModelsResponse;
                Object.keys(data.models)
                    .map((key) => data.models[key])
                    .forEach((model) => {
                        model.meta.title = t(model.meta.title);
                        Object.keys(model.schema)
                            .map((key) => model.schema[key])
                            .forEach((schema) => {
                                schema.title = t(schema.title);
                                schema.fields?.forEach((field) => {
                                    field.i18n_key = field.title;
                                    field.title = t(`${field.title}.title`);
                                    if (field.type === 'select') {
                                        if (field.source_enum) {
                                            Object.keys(field.items).forEach((key) => {
                                                field.items[key] = t(`${field.source_enum_i18n_key}.${key}`);
                                            });
                                        } else {
                                            Object.keys(field.items).forEach((key) => {
                                                field.items[key] = t(`${field.i18n_key}.values.${key}`);
                                            });
                                        }
                                    }
                                });
                            });
                    });
                dispatch({
                    type: DataActionType.LOAD_ALL_MODELS,
                    payload: data.models,
                });
                dispatch(initMenuAction(data));
                notify(dispatch, 'redux:data_actions.models_loaded', 'success');
            })
            .catch((err) => {
                notify(dispatch, 'redux:data_actions.loading_error', 'error');
                console.log(err);
            });
    };
};

export const updateTableVariableAction = <T extends TableStateFields>(
    modelMeta: UIModelMeta,
    field: T,
    data: TableState[T],
) => {
    return {
        type: DataActionType.UPDATE_TABLE_VARIABLE,
        payload: {
            modelMeta: modelMeta,
            field: field,
            data: data,
        },
    };
};

export const updateTableChainAction = <T extends TableChainFields>(
    chainId: string,
    field: T,
    data: TableChainState[T],
) => {
    return {
        type: DataActionType.UPDATE_TABLE_CHAIN_FIELD,
        payload: {
            chainId: chainId,
            field: field,
            data: data,
        },
    };
};

export const updateFormStateAction = <T extends TableStateFields>(elementId: string, field: string, value: any) => {
    return {
        type: DataActionType.UPDATE_FORM_STATE,
        payload: {
            elementId: elementId,
            field: field,
            value: value,
        },
    };
};

export const updateTableQueryFieldAction = <T extends QueryStateFields>(
    modelMeta: UIModelMeta,
    field: T,
    data: QueryState[T],
) => {
    return {
        type: DataActionType.UPDATE_TABLE_QUERY_FIELD,
        payload: {
            modelMeta: modelMeta,
            field: field,
            data: data,
        },
    };
};

export const updateTableDataAction = (tableId: string, data: SearchResult) => {
    return {
        type: DataActionType.UPDATE_TABLE_DATA_FIELD,
        payload: {
            tableId,
            data,
        },
    };
};

export const initMenuAction = (models: LoadModelsResponse) => {
    return {
        type: DataActionType.INIT_MENU,
        payload: models,
    };
};

export const addSubMenuItemAction = (menu: TableSubMenuItem, element: EditingElement) => {
    return {
        type: DataActionType.ADD_SUB_MENU,
        payload: {
            menu,
            element,
        },
    };
};

export const closeSubMenuItemAction = (id: string) => {
    return {
        type: DataActionType.CLOSE_SUB_MENU,
        payload: id,
    };
};

export const updateEditingElementAction = (element: EditingElement) => {
    return {
        type: DataActionType.UPDATE_EDITING_ELEMENT,
        payload: element,
    };
};

export const updatePartEditingElementAction = (elementId: string, updates: Record<string, any>) => {
    return {
        type: DataActionType.UPDATE_PART_EDITING_ELEMENT,
        payload: {
            elementId,
            updates,
        },
    };
};

export const replaceMenuAction = (oldMenuId: string, element: any) => {
    return {
        type: DataActionType.REPLACE_MENU,
        payload: {
            oldMenuId,
            element,
        },
    };
};

export const blockNavigateAction = (data: BlockedNavigateData) => {
    return {
        type: DataActionType.BLOCK_NAVIGATE,
        payload: data,
    };
};

export const clearTableState = (tableId: string) => {
    return {
        type: DataActionType.CLEAR_TABLE_STATE,
        payload: tableId,
    };
};

export const clearEditingElement = (elementId: string) => {
    return {
        type: DataActionType.CLEAR_EDITING_ELEMENT,
        payload: elementId,
    };
};

export const loadEditingElement = (collection: string, elementId: string, returnEmpty = true) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            let element!: EditingElement;
            const uiModel = getState().db.loadedModels[collection];
            if (uiModel) {
                const current = getState().db.edits[elementId];
                // updated element from the server copy if there are changes
                if (current && !current.changed) {
                    const res = (
                        await backend.getById(
                            uiModel.meta.collection,
                            elementId,
                            current.data.data_version?.value,
                            returnEmpty,
                        )
                    ).data;
                    if (res.changed) {
                        element = {
                            ...current,
                            data: addValidatedData(res.element),
                            deps: res.deps,
                        };
                    }
                }
                if (!getState().db.edits[elementId]) {
                    const res = (await backend.getById(uiModel.meta.collection, elementId, -1, returnEmpty)).data;
                    element = {
                        uiModel: uiModel,
                        id: res.element._id,
                        data: addValidatedData(res.element),
                        deps: res.deps,
                        isNew: false,
                        permissions: res.permissions as any,
                        changed: false,
                        createTime: getTimestamp(),
                        updatedFields: {},
                    };
                }
                if (element) {
                    dispatch(updateEditingElementAction(element));
                }
            } else {
                console.log(`${collection} ${elementId} not found`);
            }
        } catch (e: any) {
            if (e.response?.status !== 404) {
                notify(dispatch, 'common:errors.failed_to_load', 'error');
            }
        }
    };
};

export const setCurrentProjectThunk = (projectId: string, onSelect: (project?: ProjectModel) => void) => {
    return async (dispatch: any, getState: () => AppState) => {
        backend
            .getById(BaseDbCollection.project, projectId, -1, false)
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: DataActionType.SELECT_PROJECT,
                        payload: res.data.element,
                    });
                    MQClient.connect(res.data.element._id);
                    onSelect(res.data.element);
                } else {
                    throw new Error('common:errors.project_not_found');
                }
            })
            .catch((e) => {
                onSelect(undefined);
            });
    };
};

export const closeFormEditorThunk = (elementId: string, navigate: BlockNavigateFunction) => {
    return async (dispatch: any, getState: () => AppState) => {
        const menu = getState().db.menuItems.find((e) => e.id === elementId);
        if (menu && menu.type === 'sub') {
            navigate(getRoutePath(menu.parentId), {}, () => dispatch(closeSubMenuItemAction(elementId)));
        }
    };
};

export const doTableQueryThunk = <T extends QueryStateFields>(
    modelMeta: UIModelMeta,
    field: T,
    data: QueryState[T],
) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            const updateTime = field === 'updated' ? getState().db.tableData[modelMeta.tableId]?.updateTime : -1;
            dispatch(updateTableQueryFieldAction(modelMeta, field, data));
            const query: QueryState = {
                ...getState().db.tableQuery[modelMeta.tableId],
                filters: {
                    filters: [
                        ...(getState().db.tableQuery[modelMeta.tableId]?.filters?.filters || []),
                        ...(modelMeta.filters || []),
                    ],
                    expression: getState().db.tableQuery[modelMeta.tableId]?.filters?.expression,
                },
            };
            const res = await backend.tableQuery(modelMeta.collection, updateTime, query, 'list');
            if (res.updateTime !== updateTime) {
                dispatch(updateTableDataAction(modelMeta.tableId, res));
            }
        } catch (e: any) {
            console.log(`do query error: ${e}`);
            console.log(e.stack);
            notify(dispatch, e, 'error');
        }
    };
};

export const doFreeQueryThunk = (collection: string, tableId: string, query: any) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            const updateTime = getState().db.tableData[tableId]?.updateTime;
            const res = await backend.freeQuery(collection, updateTime, query);
            if (res.updateTime !== updateTime) {
                dispatch(updateTableDataAction(tableId, res));
            }
        } catch (e) {
            console.log(`do query error: ${e}`);
            notify(dispatch, e, 'error');
        }
    };
};

/**
 * Directly update a field of a database element
 *
 * @param collection
 * @param tableId
 * @param id
 * @param field
 * @param value
 * @param createElement - element will be created if the id not found
 */
export const updateOneTableItemThunk = (
    collection: string,
    tableId: string,
    id: string,
    field: string,
    value: any,
    createElement?: any,
) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            let items = [...getState().db.tableData[tableId].items];
            const edit = getState().db.edits[id];
            const index = items.findIndex((e) => e._id === id);
            if (index > -1) {
                const updated = await updateDatabase(
                    collection,
                    {
                        _id: id,
                        [field]: value,
                    },
                    false,
                );
                if (updated.element) {
                    items[index] = updated.element;
                } else {
                    items = [...items.slice(0, index), ...items.slice(index + 1)];
                }
                dispatch(
                    updateTableDataAction(tableId, {
                        ...getState().db.tableData[tableId],
                        items: items,
                    }),
                );
                if (edit) {
                    dispatch(
                        updatePartEditingElementAction(id, {
                            [field]: createValidateValue(updated.element[field], true),
                        }),
                    );
                }
            } else {
                if (!createElement) {
                    notify(dispatch, `Не найден`, 'warning');
                } else {
                    const created = await updateDatabase(collection, createElement, false);
                    items.push(created.element);
                    dispatch(
                        updateTableDataAction(tableId, {
                            ...getState().db.tableData[tableId],
                            items: items,
                        }),
                    );
                }
            }
        } catch (e: any) {
            console.log(`do query error: ${e}`);
            console.log(e.stack);
            notify(dispatch, e, 'error');
        }
    };
};

export const addTableItemThunk = (modelMeta: UIModelMeta, item: any) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            const items = [...getState().db.tableData[modelMeta.tableId].items];
            const index = items.findIndex((e) => e._id === item._id);
            if (index === -1) {
                dispatch(
                    updateTableDataAction(modelMeta.tableId, {
                        ...getState().db.tableData[modelMeta.tableId],
                        items: [item, ...items],
                    }),
                );
            } else {
                notify(dispatch, 'redux:data_actions.already_added', 'warning');
            }
        } catch (e) {
            console.log(`do query error: ${e}`);
            notify(dispatch, e, 'error');
        }
    };
};

export const openFormEditorThunk = (
    collection: string,
    itemId: string,
    navigate: NavigateFunction,
    copyMode: boolean,
) => {
    return async (dispatch: any, getState: () => AppState) => {
        const t = fixedT();
        try {
            const rootMenu = getState().db.menuItems.find(
                (e) => e.type === 'table' && e.uiModel?.meta?.collection === collection,
            ) as TableMenuItem;
            if (!rootMenu) {
                notify(dispatch, `Root menu for ${collection} not found`, 'warning');
                return;
            }
            const uiModelSchema = getState().db.loadedModels[rootMenu.uiModel.meta.collection]?.schema?.main;
            if (!uiModelSchema) {
                notify(dispatch, `UI model for ${collection} not found`, 'warning');
                return;
            }
            const edits = getState().db.edits;
            if (!copyMode) {
                if (!edits[itemId]) {
                    const res = await backend.getById(
                        rootMenu.uiModel.meta.collection,
                        itemId ? itemId : 'undefined',
                        -1,
                    );
                    const data = res.data.element;
                    const element: EditingElement = {
                        id: data._id ? data._id : v4(),
                        isNew: !data._id,
                        uiModel: rootMenu.uiModel,
                        deps: res.data.deps || [],
                        data: addValidatedData(data),
                        permissions: res.data.permissions || {
                            read: true,
                            create: true,
                            edit: true,
                            delete: true,
                        },
                        createTime: getTimestamp(),
                        updatedFields: {},
                    };
                    const subMenu = createSubMenuConfig(rootMenu, element, rootMenu.uiModel);
                    subMenu.title =
                        element.data?.name?.value || `${t('common:menu.add')} "${rootMenu.uiModel.meta.title}"`;
                    dispatch(addSubMenuItemAction(subMenu, element));
                    navigate(subMenu.path);
                } else {
                    const element = edits[itemId];
                    const subMenu = createSubMenuConfig(rootMenu, element, rootMenu.uiModel);
                    subMenu.title = element.data?.name?.value;
                    dispatch(addSubMenuItemAction(subMenu, element));
                    navigate(getRoutePath(rootMenu.uiModel.meta.tableId, itemId));
                }
            } else {
                const res = await backend.copy(rootMenu.uiModel.meta.collection, itemId);
                const data = res.data.element;
                const element: EditingElement = {
                    id: v4(),
                    isNew: true,
                    uiModel: rootMenu.uiModel,
                    deps: [],
                    data: addValidatedData(data),
                    permissions: res.data.permissions || {
                        read: true,
                        create: true,
                        edit: true,
                        delete: true,
                    },
                    createTime: getTimestamp(),
                    changed: true,
                    updatedFields: {},
                };
                const subMenu = createSubMenuConfig(rootMenu, element, rootMenu.uiModel);
                subMenu.title = element.data.name;
                dispatch(addSubMenuItemAction(subMenu, element));
                navigate(subMenu.path);
            }
        } catch (e: any) {
            console.log(`editElement error: ${e}`);
            console.log(e.stack);
            notify(dispatch, e, 'error');
        }
    };
};

export const deleteElementThunk = (modelMeta: UIModelMeta, elementId: string, cb: DataThunkCallback) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            await backend.delete(modelMeta.collection, elementId);
            dispatch(updateTableVariableAction(modelMeta, 'selected', undefined));
            dispatch(closeSubMenuItemAction(elementId));
            const tableData = { ...getState().db.tableData[modelMeta.tableId] } || {
                items: [],
                total: 0,
            };
            const index = tableData.items.findIndex((e: any) => e._id === elementId);
            if (index > -1) {
                tableData.items = [...tableData.items.slice(0, index), ...tableData.items.slice(index + 1)];
                tableData.total -= 1;
            }
            dispatch(updateTableDataAction(modelMeta.tableId, tableData));
            cb(true);
        } catch (e) {
            console.log(`deleteElement error: ${e}`);
            cb(false);
            notify(dispatch, e, 'error');
        }
    };
};

export const saveElementThunk = (element: EditingElement, navigate?: NavigateFunction) => {
    return async (dispatch: any, getState: () => AppState) => {
        try {
            const updated: EditingElement = {
                ...element,
                changed: false,
                updatedFields: {},
            };
            const body = Object.keys(element.updatedFields).reduce(
                (pv: any, cv) => {
                    pv[cv] = element.data[cv];
                    return pv;
                },
                {
                    _id: element.data._id,
                },
            );
            const res = (await updateDatabase(element.uiModel.meta.collection, body)).element;
            let tableData = { ...getState().db.tableData[element.uiModel.meta.tableId] } || {
                items: [],
                total: 0,
            };
            if (element.isNew) {
                updated.isNew = false;
                updated.data = addValidatedData(res);
                updated.id = res._id;
                dispatch(replaceMenuAction(element.id, updated));
                if (navigate) {
                    navigate(getRoutePath(element.uiModel.meta.tableId, updated.id), { replace: true });
                }
                // add new in the cached items
                tableData.items = [res, ...tableData.items];
                tableData.total += 1;
            } else {
                dispatch(updateEditingElementAction(updated));
                const index = tableData?.items?.findIndex((e: DBElement) => e._id === res._id);
                if (index > -1) {
                    tableData.items = updateInnerField(tableData.items, [index], res);
                }
            }
            dispatch(updateTableDataAction(element.uiModel.meta.tableId, tableData));
            notify(dispatch, 'redux:data_actions.saved', 'info');
        } catch (e: any) {
            console.log(`saveElementThunk error: ${e}`);
            console.log(e.stack);
            notify(dispatch, e, 'error');
        }
    };
};
