import { Button } from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import objectPath from 'object-path';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteInnerField, pushField, updateInnerField } from '../../utils/ModelUtils';
import { DataFormOnChangeFunc } from '../DataEditor/DataForm/DataFormTypes';

type ListEditorProps = {
    defaultListValue: any;
    listFieldName: string;
    listEditorComponent: React.ComponentType<ListEditorComponentType>;
    data: any;
    onChange: DataFormOnChangeFunc;
    readonly: boolean;
    helpPrefix?: string[];
};

export type ListEditorComponentType<T = any> = {
    index: number;
    row: T;
    onChange: (path: string[], value: any) => void;
    onDelete: () => void;
    readonly: boolean;
    helpPrefix?: string[];
    total: number;
};

const ListEditor: FunctionComponent<ListEditorProps> = (props: ListEditorProps) => {
    const { onChange, defaultListValue, data, readonly, listEditorComponent, helpPrefix } = props;
    const { t } = useTranslation();
    const Component = listEditorComponent;
    const [draggedElement, setDraggedElement] = useState<number | undefined>(undefined);
    const [elementOverIndex, setElementOverIndex] = useState<number | undefined>(undefined);

    const rootPath = props.listFieldName.split('.');
    const arr = objectPath.get(data, rootPath);

    const onDragStart = (event: any, index: number) => {
        setDraggedElement(index);
        setElementOverIndex(index);
    };

    const onDragOver = (event: any, index: number) => {
        if (elementOverIndex === index) return;
        setElementOverIndex(index);
    };

    const onDragEnd = () => {
        if (draggedElement === undefined || elementOverIndex === undefined) return;
        if (draggedElement !== elementOverIndex) {
            const dragged = arr.splice(draggedElement, 1);
            arr.splice(elementOverIndex, 0, dragged[0]);
            onChange((data: any) => {
                return updateInnerField(data, rootPath, arr);
            });
        }
        setDraggedElement(undefined);
        setElementOverIndex(undefined);
    };

    const handleChange = (index: number, path: string[], value: any) => {
        onChange((data: any) => {
            return updateInnerField(data, [...rootPath, index, ...path], value);
        });
    };

    const handleAdd = () => {
        onChange((data: any) => pushField(data, [...rootPath], cloneDeep(defaultListValue)));
    };

    const handleDelete = (index: number) => {
        onChange((data: any) => deleteInnerField(data, [...rootPath, index]));
    };

    return (
        <div>
            <div style={{ alignSelf: 'end', display: 'flex' }}>
                <div style={{ flex: 1 }} />
                <Button variant={'outlined'} color={'secondary'} onClick={handleAdd} disabled={readonly}>
                    {t('common:buttons.add')}
                </Button>
            </div>
            {arr.map((e: any, index: number) => {
                return (
                    <div
                        draggable
                        key={index}
                        onDragStart={(e) => onDragStart(e, index)}
                        onDragEnd={(e) => onDragEnd()}
                        onDragOver={(e) => onDragOver(e, index)}>
                        <Component
                            index={index}
                            total={arr.length}
                            row={e}
                            readonly={readonly}
                            onDelete={() => handleDelete(index)}
                            onChange={(path: string[], value: any) => handleChange(index, path, value)}
                            helpPrefix={helpPrefix}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ListEditor;
