import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { backend } from '../../../data/Backend';
import { deleteValidatedData } from '../../../utils/ValidatedValueUtils';
import { FieldWidgetProps } from '../../Utils/FieldWidgetProps';

type Props = FieldWidgetProps;

const colors: Record<string, string> = {
    REGED: 'green',
    ERR: 'red',
};

const TrunkStatusField: FunctionComponent<Props> = (props: Props) => {
    const value = deleteValidatedData(props.data);
    const timer = useRef<NodeJS.Timer | undefined>();
    const [status, setStatus] = useState<string>('');
    useEffect(() => {
        if (value._id) {
            backend.trunkStatus(value._id).then(setStatus);
            timer.current = setInterval(async () => {
                const s = await backend.trunkStatus(value._id);
                setStatus(s);
            }, 5000);
            return () => {
                if (timer.current) {
                    clearInterval(timer?.current);
                }
            };
        }
    }, [props.data]);
    return (
        <span>
            {props.config.title}: <span style={{ color: colors[status] || 'black' }}>{status}</span>
        </span>
    );
};

export default TrunkStatusField;
