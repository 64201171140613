import { UIModelFieldType } from '@aatdev/common-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createValidateValue } from '../../utils/ValidatedValueUtils';
import DataInput from '../DataEditor/DataForm/DataInput';

type Props = {
    open: boolean;
    onClose: () => void;
    onUpload: (checkUnique: boolean, maxInvalidRows: number) => void;
    onDelete: (file: File) => void;
    onAdd: (files: File[]) => void;
    files: File[];
};

const UploadDialog: FunctionComponent<Props> = (props) => {
    const { open, onClose, onUpload, onAdd, onDelete, files } = props;
    const { t } = useTranslation();
    const [checkUnique, setCheckUnique] = useState(true);
    const [maxInvalidRows, setMaxInvalidRows] = useState(100);
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('dialogs:upload.title')}</DialogTitle>
            <DialogContent style={{ minWidth: 500 }}>
                <DropzoneAreaBase
                    fileObjects={files.map((f) => ({
                        file: f,
                        data: null,
                    }))}
                    onDelete={(fo) => onDelete(fo.file)}
                    acceptedFiles={['text/*']}
                    showPreviews={false}
                    maxFileSize={5000000}
                    filesLimit={1}
                    onDrop={onAdd}
                    showFileNames={true}
                    showFileNamesInPreview={true}
                    showPreviewsInDropzone={true}
                    dropzoneText={t('dialogs:upload.dropzoneText')}
                />
                <DataInput
                    config={{
                        type: UIModelFieldType.boolean,
                        title: t('dialogs:upload.checkUnique'),
                        field: '',
                    }}
                    value={createValidateValue(checkUnique)}
                    fullWidth={true}
                    onChange={(value) => setCheckUnique(value.value)}
                />
                <DataInput
                    config={{
                        type: UIModelFieldType.number,
                        title: t('dialogs:upload.maxInvalidRows'),
                        field: '',
                    }}
                    value={createValidateValue(maxInvalidRows)}
                    fullWidth={true}
                    onChange={(value) => setMaxInvalidRows(value.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    {t('common:buttons.cancel')}
                </Button>
                <Button onClick={() => onUpload(checkUnique, maxInvalidRows)} color='primary' autoFocus>
                    {t('common:buttons.upload')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UploadDialog;
