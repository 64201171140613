import { DialerCampaignCurrentCounters, DialerCampaignLimits } from '@aatdev/dialer-types';
import { Card, CardContent } from '@material-ui/core';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { backend } from '../../../data/Backend';
import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import { CallStatisticsCard } from '../Utils/CallStatisticsCard';

type Props = {} & DataFormProps;

const zeroStat = Object.keys(DialerCampaignLimits).reduce((pv: any, cv) => {
    pv[cv] = 0;
    return pv;
}, {});

export const UploadListStatistics: FunctionComponent<Props> = (props) => {
    const { element } = props;
    const { t } = useTranslation();
    const [stat, setStat] = useState<DialerCampaignCurrentCounters>(zeroStat);
    useEffect(() => {
        if (element?.data?._id) {
            backend.getUploadListStat(element?.data?._id?.value).then((e) => setStat(e));
        }
    }, [element?.data?._id]);

    return (
        <Card>
            <CardContent>
                <CallStatisticsCard header={t('forms:stats.campaign.summary.headers.total')} totalData={stat} />
            </CardContent>
        </Card>
    );
};
export default UploadListStatistics;
