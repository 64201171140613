import { DialerCallingListModel, DialerCollections } from '@aatdev/dialer-types';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useFindById } from '../../../data/UseQuery';
import { AppState } from '../../../store/StoreTypes';
import UploadedPhoneListTable from '../Tables/UploadedPhoneListTable';

type Props = {};

const ContactReport: FunctionComponent<Props> = (props: Props) => {
    const tableId = 'contact_report';
    // get a calling list from the table filters
    // the calling list in the filter is a cut db element with _id and name fields only
    const callingListFromFilter = useSelector(
        (state: AppState) =>
            state.db.tableQuery[tableId]?.filters?.filters?.find((f) => f.name === 'calling_list_id')?.value,
        (left, right) => {
            return left === right;
        },
    );
    const callingList: DialerCallingListModel = useFindById(
        DialerCollections.calling_list,
        callingListFromFilter?._id,
    )?.data;
    return (
        <UploadedPhoneListTable
            tableId={tableId}
            params={{
                callingList: callingList,
                additinalFields: ['calling_list_id'],
                excludedFields: ['uploaded_list_id'],
            }}
        />
    );
};

export default ContactReport;
