import { PermissionTypes, UIModelFieldType } from '@aatdev/common-types';
import { DialerCallingListModel, DialerCollections } from '@aatdev/dialer-types';
import { Switch } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { backend } from '../../../data/Backend';
import { usePermissions } from '../../../data/UseQuery';
import { addTableItemThunk, deleteElementThunk, updateOneTableItemThunk } from '../../../store/actions/DataActions';
import { startLoading, stopLoading } from '../../../store/actions/GeneralActions';
import { getToolbarTopMargin } from '../../../utils/AppTheme';
import { cloneModel } from '../../../utils/ModelUtils';
import { notify } from '../../../utils/NotifierHelpers';
import { useModel } from '../../../utils/UseModel';
import CommonDataTable from '../../CommonDataTable/CommonDataTable';
import { CommonDataTableButtonsType } from '../../CommonDataTable/CommonDataTableTypes';
import { createFieldConfig } from '../../CommonDataTable/CommonDataTableUtils';
import { PreconfiguredDataTableProps } from '../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableTypes';
import UploadDialog from '../../Dialogs/UploadDialog';

type Props = PreconfiguredDataTableProps<{
    callingList: DialerCallingListModel;
}>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: `calc(100vh - ${getToolbarTopMargin(5)}px)`,
        },
    }),
);

const UploadedListTable: FunctionComponent<Props> = (props) => {
    const { filters, tableId, editable, params, onSelect, breadcrumbs } = props;
    const { callingList } = params || {};
    const { t } = useTranslation();
    const styles = useStyles();
    const dispatch = useDispatch();
    const [uploadOpen, setUploadOpen] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const origModel = useModel(DialerCollections.uploaded_list);
    const permissions = usePermissions(DialerCollections.uploaded_list);

    if (!origModel || !permissions) {
        return <span />;
    }

    const uiModel = cloneModel(origModel, [
        'name',
        'upload_date',
        'description',
        'total',
        'total_completed',
        'total_valid',
        'total_invalid',
        'total_doubles',
        'finished',
        'expire_date',
    ]);
    uiModel.meta.delete_if_deps = true;
    uiModel.meta.tableId = tableId;
    const ff = filters || {
        filters: [],
    };
    if (callingList) {
        ff.filters.push({
            type: 'string',
            value: callingList._id,
            operator: 'eq',
            name: 'calling_list_id',
        });
    }
    if (editable) {
        uiModel.schema.main.fields.push(
            createFieldConfig(
                UIModelFieldType.boolean,
                t('tables:uploaded_list.tabs.main.fields.enabled.title'),
                'enabled',
                {
                    maxWidth: 85,
                    fast_searchable: false,
                    render: ({ data }: any) => {
                        return (
                            <Switch
                                color={'primary'}
                                checked={data.enabled}
                                disabled={!permissions[PermissionTypes.canUploadLists]}
                                onChange={(event, checked) => {
                                    dispatch(
                                        updateOneTableItemThunk(
                                            uiModel.meta.collection,
                                            uiModel.meta.tableId,
                                            data._id,
                                            'enabled',
                                            checked,
                                        ),
                                    );
                                }}
                            />
                        );
                    },
                },
            ),
        );
    }

    const handleUpload = async (checkUnique: boolean, maxInvalidRows: number) => {
        for (const file of files) {
            const data = new FormData();
            data.append('file', file);
            try {
                dispatch(startLoading());
                const res = await backend.update(
                    `uploaded_list/upload/${
                        callingList?._id || ''
                    }?checkUnique=${checkUnique}&maxInvalidRows=${maxInvalidRows}`,
                    data,
                );
                dispatch(addTableItemThunk(uiModel.meta, res.data));
            } catch (e: any) {
                notify(dispatch, e, 'error');
            } finally {
                dispatch(stopLoading());
            }
        }
        setFiles([]);
        setUploadOpen(false);
    };

    const handleAdd = (files: File[]) => {
        setFiles(files);
    };

    const handleDelete = (file: File) => {
        setFiles(files.filter((f) => f.name !== file.name));
    };

    const buttons: CommonDataTableButtonsType = {};
    if (editable) {
        if (permissions[PermissionTypes.canUploadLists]) {
            buttons.create = {
                label: t('common:buttons.upload'),
                enabled: true,
                handler: (id, cb) => {
                    setUploadOpen(true);
                },
            };
        }
        if (permissions.delete) {
            buttons.remove = {
                label: t('common:buttons.delete'),
                handler: (id, cb) => {
                    dispatch(deleteElementThunk(uiModel.meta, id, cb));
                },
            };
        }
    }

    return (
        <>
            <UploadDialog
                files={files}
                open={uploadOpen}
                onUpload={handleUpload}
                onAdd={handleAdd}
                onDelete={handleDelete}
                onClose={() => setUploadOpen(false)}
            />
            <CommonDataTable
                type={'table'}
                filters={ff}
                className={styles.table}
                uiModel={uiModel}
                tableButtons={buttons}
                toolbar={breadcrumbs}
                previewMode={true}
                readonlyPreview={false}
                onDblClick={(element) => {
                    if (onSelect) {
                        onSelect(element);
                    }
                }}
            />
        </>
    );
};

export default UploadedListTable;
