import React, { FunctionComponent } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

type Props = any;

const FormOverlay: FunctionComponent<Props> = (props) => {
    const { children, visible, className, fullSize, target, onHide, ...restProps } = props;
    return (
        <Dialog onClose={onHide} open={visible} maxWidth={'lg'}>
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default FormOverlay;
