import { DBElementPermissions, ElementWithDeps, SearchResult } from '@aatdev/common-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEditingElement } from '../store/actions/DataActions';
import { QueryState, TableState } from '../store/actions/DataActionTypes';
import { AppState } from '../store/StoreTypes';
import { useEditableElement } from '../utils/UseEditableElement';
import { deleteValidatedData } from '../utils/ValidatedValueUtils';
import { backend } from './Backend';

/**
 * Get table data from the redux
 * @param tableId
 */
export const useTableData = (tableId: string): SearchResult => {
    return (
        useSelector((state: AppState) => state.db.tableData[tableId]) || {
            items: [],
            total: 0,
            hasNext: false,
        }
    );
};

export const useTableQuery = (tableId: string): QueryState => {
    return useSelector((state: AppState) => state.db.tableQuery[tableId]) || {};
};

export const useTableState = (tableId: string): TableState => {
    return useSelector((state: AppState) => state.db.tableState[tableId]) || {};
};

/**
 * Loading element by an id or use the editing version from "edits"
 * From the returned data removed validation wrapper
 * @param collection
 * @param id
 */
export const useFindById = <T extends ElementWithDeps<any>>(collection: string, id?: string) => {
    const edit = useEditableElement(id);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!edit && id) {
            dispatch(loadEditingElement(collection, id));
        }
    }, [id]);
    if (edit) {
        return {
            ...edit,
            data: deleteValidatedData(edit.data),
        };
    }
    return undefined;
};

/**
 * Get permissions
 * @param collection
 */
export const usePermissions = (collection: string) => {
    const [item, setItem] = useState<DBElementPermissions>();
    useEffect(() => {
        backend.getPermissions(collection).then((res) => {
            if (res.data) {
                setItem(res.data as any);
            }
        });
    }, [collection]);
    return item;
};
