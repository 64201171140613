import React, { FunctionComponent } from 'react';

export const parseMarkdown = (text: string) => {
    const toHTML = text
        .replace(/^##### (.*$)/gim, '<h5>$1</h5>')
        .replace(/^#### (.*$)/gim, '<h4>$1</h4>')
        .replace(/^### (.*$)/gim, '<h3>$1</h3>')
        .replace(/^## (.*$)/gim, '<h2>$1</h2>') // h2 tag
        .replace(/^# (.*$)/gim, '<h1>$1</h1>') // h1 tag
        .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>') // bold text
        .replace(/\*(.*)\*/gim, '<i>$1</i>'); // italic text
    const lines: string[] = [];
    let ulStarted = false;
    for (const line of toHTML.split(/\n/)) {
        if (line === '') {
            lines.push('</p>');
            lines.push('<p>');
        }
        if (line.startsWith('- ')) {
            if (!ulStarted) {
                ulStarted = true;
                lines.push('<ul>');
            }
            lines.push(`<li>${line.substring(2)}</li>`);
        } else {
            if (ulStarted) {
                lines.push('</ul>');
                ulStarted = false;
            }
            lines.push(line);
        }
    }
    return lines.join('\n');
};

type Props = {
    children: string;
};

const Markdown: FunctionComponent<Props> = (props) => {
    return <div dangerouslySetInnerHTML={{ __html: parseMarkdown(props.children) }} />;
};

export default Markdown;
