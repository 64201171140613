import { createTheme } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';

const appTheme = createTheme({
    palette: {
        primary: deepPurple,
    },
});

export const getToolbarTopMargin = (mult: number = 1) => {
    return appTheme.spacing(8) * mult;
};

export default appTheme;
