import { StompCampaignStatMsg, StompProjectStatMsg, StompTopics } from '@aatdev/dialer-types/dist';
import { Client } from '@stomp/stompjs';
import { updateCampaignStat, updateProjectStat } from '../store/actions/StatsActions';
import { store } from '../store/RootStore';
import { getURLFromEnv } from '../utils/MiscUtils';

class MQClient {
    private client: Client | undefined;

    connect(projectId: string) {
        const url = getURLFromEnv('REACT_APP_MQ_BACKEND_URL');
        console.log('STOMP connect to', url);
        if (this.client?.connected) {
            this.client?.deactivate();
        }
        this.client = new Client({
            brokerURL: url,
            connectHeaders: {},
            debug: function (str) {
                // console.log(str);
            },
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
        });

        this.client.onConnect = (frame) => {
            console.log('STOMP connected');
            this.subscribeStat(projectId);
            setTimeout(() => {
                this.getInitialProjectStat(projectId);
            }, 1000);
        };

        this.client.onStompError = (frame) => {
            // Will be invoked in case of error encountered at Broker
            // Bad login/passcode typically will cause an error
            // Complaint brokers will set `message` header with a brief message. Body may contain details.
            // Compliant brokers will terminate the connection after any error
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
            this.client?.deactivate();
            this.client?.activate();
        };
        this.client.activate();
    }

    public disconnect() {
        this.client?.deactivate();
    }

    private subscribeStat(projectId: string) {
        const func = () => {
            setTimeout(() => {
                if (this.client?.connected) {
                    this.client?.subscribe(`${projectId}.${StompTopics.getLiveProjectStat}`, (message) => {
                        const msg: StompProjectStatMsg = JSON.parse(message.body);
                        console.log(msg);
                        store.dispatch(updateProjectStat(msg));
                    });
                    this.client?.subscribe(`${projectId}.${StompTopics.getLiveCampaignStat}`, (message) => {
                        const msg: StompCampaignStatMsg = JSON.parse(message.body);
                        console.log(msg);
                        store.dispatch(updateCampaignStat(msg));
                    });
                } else {
                    func();
                }
            }, 1000);
        };
        func();
    }

    public getInitialProjectStat(projectId: string) {
        if (this.client?.connected) {
            this.client?.publish({
                destination: StompTopics.getInitialStat,
                body: JSON.stringify({ projectId }),
            });
        }
    }
}

export default new MQClient();
