import { SnackbarKey } from 'notistack';
import { NotiferActionType, NotificationType, NotifierAction } from './NotiferActionTypes';

export const enqueueSnackbar = (notification: NotificationType): NotifierAction => {
    const key = notification.key;

    return {
        type: NotiferActionType.ENQUEUE_SNACKBAR,
        payload: {
            notification: {
                ...notification,
                key: key || new Date().getTime() + Math.random(),
            },
        },
    };
};

export const closeSnackbar = (key: SnackbarKey): NotifierAction => ({
    type: NotiferActionType.CLOSE_SNACKBAR,
    payload: {
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    },
});

export const removeSnackbar = (key: SnackbarKey): NotifierAction => ({
    type: NotiferActionType.REMOVE_SNACKBAR,
    payload: {
        key,
    },
});
