import { IconButton, Menu, MenuItem, SvgIcon } from '@material-ui/core';
import { FC, useState } from 'react';
import { ReactComponent as ExcelIcon } from '../../assets/excel.svg';
import { useTranslation } from 'react-i18next';
import { TableExporterType } from '@aatdev/common-types';

type Props = {
    disabled: boolean;
    onExport: (type: TableExporterType) => void;
};

const CommonDataTableExportButton: FC<Props> = (props) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleExport = (type: TableExporterType) => () => {
        props.onExport(type);
        handleClose();
    };

    return (
        <>
            <IconButton
                color={'primary'}
                onClick={handleClick}
                disabled={props.disabled}
                title={t('common:buttons.export')}>
                <SvgIcon>
                    <ExcelIcon />
                </SvgIcon>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <MenuItem onClick={handleExport(TableExporterType.xlsx)}>XLSX</MenuItem>
                <MenuItem onClick={handleExport(TableExporterType.csv)}>CSV</MenuItem>
            </Menu>
        </>
    );
};

export default CommonDataTableExportButton;
