import { UIModelField, UIModelFieldType, ValidatedValue } from '@aatdev/common-types';
import DateFnsUtils from '@date-io/date-fns';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DateTimePicker } from '@material-ui/pickers/DateTimePicker';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { TimePicker } from '@material-ui/pickers/TimePicker';
import * as React from 'react';
import { FunctionComponent, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getDateFsLocale } from '../../../utils/DateUtils';
import { ReadOnlyContext } from '../../../utils/ReadOnlyContext';
import { getCustomComponent } from '../../Custom';
import EntityAutocomplete from '../../EntityAutocomplete/EntityAutocomplete';
import HelpButton from '../../HelpWrapper/HelpButton';
import { getConstrain, validateAgainstConstraint } from './DataInputConstrains';

type Props = {
    config: UIModelField;
    value: ValidatedValue<any>;
    onChange: (value: ValidatedValue<any>) => void;
    isNew?: boolean;
    default?: any;
    constrains?: any;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
    uuid?: string;
    fullWidth?: boolean;
    externalValidation?: boolean;
    shrink?: boolean;
    helpId?: string[];
};

const inputVariant = 'outlined';

const DataInput: FunctionComponent<Props> = (props: Props) => {
    const { config, value, constrains, onChange, isNew, disabled, uuid, fullWidth, externalValidation, helpId } = props;
    const { t } = useTranslation();
    const readonly = useContext(ReadOnlyContext);
    const shrink = props.shrink !== false;

    const validateIt = (value: any) => {
        const constrain = getConstrain(constrains);
        return validateAgainstConstraint(value, constrain);
    };

    const handleChange = async (input: any) => {
        const v = input === undefined || input === null ? props.default : input;
        let valid = value?.___valid___;
        let messages = value?.messages;
        if (!externalValidation) {
            const vv = validateIt(v);
            valid = vv.valid;
            messages = vv.messages;
        }
        await onChange({
            value: v,
            ___valid___: valid,
            messages: messages,
        });
    };

    const validationResult = !externalValidation
        ? validateIt(value?.value)
        : {
              valid: value?.___valid___ || readonly,
              messages: value?.messages,
          };

    const messages = validationResult?.messages?.map((e) => e)?.join(', ') || ' ';
    //const messages = "";
    const title = config.title;

    // validate input on mount time
    useEffect(() => {
        handleChange(value?.value);
    }, [constrains, uuid]);

    const notEditable = config.editable === false || (!isNew && config.persistent) || disabled || readonly;
    const endAdorment = helpId ? (
        <InputAdornment position='end'>
            <HelpButton type={'popup'} helpId={helpId} header={config.title} />
        </InputAdornment>
    ) : null;

    const getInput = () => {
        switch (config.type) {
            case UIModelFieldType.date:
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFsLocale()}>
                        <DateTimePicker
                            className={props.className}
                            clearable={config.validator === undefined}
                            style={props.style}
                            autoComplete='off'
                            emptyLabel={messages}
                            label={title}
                            // only null is treated as empty value
                            value={!value?.value ? null : value.value}
                            format='dd/MM/yyyy HH:mm:ss'
                            disabled={notEditable}
                            ampm={false}
                            margin={'dense'}
                            title={messages}
                            error={!validationResult.valid}
                            inputVariant={'outlined'}
                            onChange={(date) => {
                                handleChange(!date ? '' : date);
                            }}
                            InputProps={{
                                endAdornment: endAdorment,
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            case UIModelFieldType.time:
                return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateFsLocale()}>
                        <TimePicker
                            className={props.className}
                            style={props.style}
                            autoComplete='off'
                            ampm={false}
                            inputVariant='outlined'
                            emptyLabel={messages}
                            label={title}
                            // only null is treated as empty value
                            value={!value?.value ? null : value.value}
                            format='HH:mm'
                            disabled={notEditable}
                            margin={'dense'}
                            title={messages}
                            error={!validationResult.valid}
                            onChange={(date) => {
                                handleChange(date);
                            }}
                            InputProps={{
                                endAdornment: endAdorment,
                            }}
                        />
                    </MuiPickersUtilsProvider>
                );
            case UIModelFieldType.entity:
                const multiselect = !!config.multiselect;
                return (
                    <div className={props.className}>
                        <EntityAutocomplete
                            label={title}
                            variant={inputVariant}
                            helperText={messages}
                            error={!validationResult.valid}
                            entityType={config.entity_type}
                            entityConditions={config.entity_conditions}
                            value={multiselect && !value?.value ? [] : value?.value}
                            disabled={notEditable}
                            multiple={multiselect}
                            onChange={(option) => {
                                if (option) {
                                    handleChange(option);
                                }
                            }}
                            helpId={props.helpId}
                        />
                    </div>
                );
            case UIModelFieldType.boolean:
                const items = config.items || {
                    true: t('common:input.boolean.true'),
                    false: t('common:input.boolean.false'),
                };
                return (
                    <TextField
                        type={'text'}
                        select
                        autoComplete='off'
                        size={'small'}
                        title={messages}
                        style={props.style}
                        className={props.className}
                        value={value?.value?.toString()}
                        label={title}
                        error={!validationResult.valid}
                        fullWidth={fullWidth}
                        variant={inputVariant}
                        margin='dense'
                        disabled={notEditable}
                        onChange={(event) => {
                            handleChange(event.target.value == 'true');
                        }}
                        inputProps={{
                            style: {
                                backgroundColor: 'white',
                            },
                        }}
                        InputProps={{
                            endAdornment: endAdorment,
                        }}
                        InputLabelProps={{ shrink: shrink }}>
                        {Object.keys(items).map((key) => (
                            <MenuItem key={key} value={key}>
                                {items[key]}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            case UIModelFieldType.select:
                return (
                    <div>
                        <Autocomplete
                            fullWidth={fullWidth}
                            disabled={notEditable}
                            className={props.className}
                            options={Object.keys(config.items)}
                            getOptionLabel={(field) =>
                                config.items[field]?.label || config.items[field]?.toString() || ''
                            }
                            getOptionDisabled={(field) => !!config.items[field]?.disabled}
                            value={value?.value || ''}
                            filterSelectedOptions
                            selectOnFocus={false}
                            disableClearable={config.disableClearable}
                            multiple={!!config.multiselect}
                            onChange={(event, val) => handleChange(val)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={props.style}
                                    autoComplete='off'
                                    title={messages}
                                    error={!validationResult.valid}
                                    size={'small'}
                                    variant={inputVariant}
                                    label={title}
                                    InputLabelProps={{ shrink: shrink }}
                                    disabled={notEditable}
                                    fullWidth={fullWidth}
                                    margin={'dense'}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                <InputAdornment position='end'>
                                                    {params.InputProps.endAdornment}
                                                    {helpId && <HelpButton type={'popup'} helpId={helpId} />}
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </div>
                );
            case UIModelFieldType.number:
            case UIModelFieldType.int:
                return (
                    <TextField
                        type={config.type === 'int' || config.type === 'number' ? 'number' : 'text'}
                        autoComplete='off'
                        size={'small'}
                        variant={inputVariant}
                        style={props.style}
                        className={props.className}
                        value={value?.value || ''}
                        label={title}
                        title={messages}
                        error={!validationResult.valid}
                        fullWidth={fullWidth}
                        margin='dense'
                        disabled={notEditable}
                        onChange={(event) => {
                            if (!event.target.value) {
                                handleChange('');
                            } else {
                                if (validateIt(event.target.value).valid) {
                                    handleChange(event.target.value);
                                }
                            }
                        }}
                        InputProps={{
                            endAdornment: endAdorment,
                        }}
                        InputLabelProps={{ shrink: shrink }}
                    />
                );
            case UIModelFieldType.password:
                return (
                    <TextField
                        type={'password'}
                        autoComplete='off'
                        size={'small'}
                        style={props.style}
                        className={props.className}
                        value={value?.value || ''}
                        label={title}
                        title={messages}
                        error={!validationResult.valid}
                        fullWidth={fullWidth}
                        variant={inputVariant}
                        margin='dense'
                        disabled={notEditable}
                        onChange={(event) => {
                            handleChange(event.target.value);
                        }}
                        InputLabelProps={{ shrink: shrink }}
                        inputProps={{
                            autoComplete: 'new-password',
                            form: {
                                autoComplete: 'off',
                            },
                        }}
                        InputProps={{
                            endAdornment: endAdorment,
                        }}
                    />
                );
            case UIModelFieldType.widget:
                const Comp = getCustomComponent(config.field);
                return <Comp data={value} config={config} />;
        }
        return (
            <TextField
                type={config.type}
                autoComplete='off'
                size={'small'}
                title={messages}
                style={props.style}
                className={props.className}
                value={value?.value || ''}
                label={title}
                error={!validationResult.valid}
                fullWidth={fullWidth}
                variant={inputVariant}
                margin='dense'
                multiline={config.type === 'text' && config.multiline}
                disabled={notEditable}
                onChange={(event) => {
                    handleChange(event.target.value);
                }}
                inputProps={{
                    style: {
                        backgroundColor: 'white',
                    },
                }}
                InputProps={{
                    endAdornment: endAdorment,
                }}
                InputLabelProps={{ shrink: shrink }}
            />
        );
    };

    return getInput();
};

export default DataInput;
