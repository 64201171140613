import { DialerCampaignModel, DialerCollections, DialerSelectionModel } from '@aatdev/dialer-types';
import { FunctionComponent } from 'react';
import { useFindById } from '../../../data/UseQuery';
import { deleteValidatedData } from '../../../utils/ValidatedValueUtils';
import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import SelectionPhoneListChain from '../Tables/Chains/SelectionPhoneListChain';

type Props = {} & DataFormProps;

const CampaignPhoneList: FunctionComponent<Props> = (props) => {
    const { data } = props.element;
    const campaign: DialerCampaignModel = deleteValidatedData(data);
    const selection: DialerSelectionModel = useFindById(DialerCollections.selection, campaign?.selection_id?._id)?.data;

    if (!campaign || !selection || !data?._id) {
        return <span />;
    }

    return <SelectionPhoneListChain selection={selection} prefixTableId={data._id.value} key={selection._id} />;
};

export default CampaignPhoneList;
