import { LoggedUser } from '@aatdev/common-types';
import { GeneralActionType } from './GeneralActionTypes';

export const changeLoggedStateAction = (user?: LoggedUser) => {
    return {
        type: GeneralActionType.LOGGED_STATE,
        payload: user,
    };
};

export const setStateVariable = (name: string, value: any) => {
    return {
        type: GeneralActionType.SET_VARIABLE,
        payload: {
            name,
            value,
        },
    };
};

export const clearStateVariable = (name: string) => {
    return {
        type: GeneralActionType.CLEAR_VARIABLE,
        payload: name,
    };
};

export const startLoading = (message?: string) => ({
    type: GeneralActionType.START_LOADING,
    payload: message,
});

export const stopLoading = () => ({
    type: GeneralActionType.STOP_LOADING,
});
