import { createStyles, createTheme, makeStyles, Theme } from '@material-ui/core/styles';

export const materialTheme = createTheme({
    overrides: {
        MuiPickersCalendarHeader: {
            switchHeader: {
                display: 'none',
            },
        },
        MuiPickersDay: {
            daySelected: {
                color: 'black',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
});

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            fontWeight: 'bold',
            textAlign: 'center',
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: '1em',
            margin: 'auto',
            width: '90%',
            backgroundColor: theme.palette.background.paper,
            alignItems: 'center',
        },
        monthCellContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        monthCell: {
            textAlign: 'center',
            outline: 'auto',
            outlineColor: theme.palette.background.default,
        },
        workDay: {
            backgroundColor: theme.palette.info.light,
            borderRadius: '50%',
        },
        dayEditor: {
            padding: '1em',
        },
        circle: {
            width: 30,
            height: 30,
            borderRadius: '50%',
            outline: 'auto',
            marginRight: 10,
        },
    }),
);
