import { UIModel, UIModelMeta } from '@aatdev/common-types';
import { Box, Button, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React, { FunctionComponent, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { usePermissions, useTableState } from '../../../data/UseQuery';
import { updateFormStateAction, updateTableVariableAction } from '../../../store/actions/DataActions';
import { EditingElement } from '../../../store/actions/DataActionTypes';
import { getToolbarTopMargin } from '../../../utils/AppTheme';
import { filterDict } from '../../../utils/MiscUtils';
import { isFieldVisible } from '../../../utils/ModelUtils';
import { ReadOnlyContext } from '../../../utils/ReadOnlyContext';
import { useFormState } from '../../../utils/UseFormState';
import { deleteValidatedData, isDataValid, isMainTab } from '../../../utils/ValidatedValueUtils';
import { getCustomComponent } from '../../Custom';
import HelpButton from '../../HelpWrapper/HelpButton';
import DataForm from './DataForm';
import { DataFormOnChangeFunc } from './DataFormTypes';

type Props = {
    uiModel: UIModel;
    element: EditingElement;
    onChange?: (element: EditingElement, values: Record<string, any>) => void;
    onClose?: (element: EditingElement) => void;
    onSave?: (element: EditingElement) => void;
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        minHeight: '100%',
        width: '100%',
        margin: 'auto',
        padding: 0,
    },
    buttons: {
        display: 'flex',
        width: '100%',
        '& button': {
            marginLeft: '1em',
        },
    },
});

const useCurrentTab = (modelMeta: UIModelMeta) => {
    return useTableState(modelMeta.tableId)?.current_tab;
};

const DataFormTabs: FunctionComponent<Props> = (props: Props) => {
    const { uiModel, element, onChange, onSave, onClose } = props;
    const styles = useStyles();
    const dispatch = useDispatch();
    const currentTab = useCurrentTab(uiModel.meta) || Object.keys(uiModel.schema)[0];
    const formState = useFormState(element.id);
    const { t } = useTranslation();
    const readonly = useContext(ReadOnlyContext);
    const permissions = usePermissions(uiModel.meta.collection);
    const visibleSchemas = filterDict(uiModel.schema, (item) =>
        isFieldVisible(deleteValidatedData(element?.data || {}), item),
    );

    const editByFieldPerm = useMemo(
        () =>
            Object.keys(visibleSchemas).reduce((pv, field) => {
                if (isMainTab(field)) {
                    for (const n of visibleSchemas.main.fields) {
                        if (n.editable_by_permission && permissions?.[n.editable_by_permission]) {
                            return pv || true;
                        }
                    }
                } else {
                    const e = visibleSchemas[field].editable_by_permission;
                    if (e && permissions?.[e]) {
                        return pv || true;
                    }
                }
                return pv;
            }, false),
        [uiModel, permissions],
    );

    if (!element) {
        return <span />;
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        dispatch(updateTableVariableAction(uiModel.meta, 'current_tab', newValue));
    };

    const handleDataChange =
        (tabId: string): DataFormOnChangeFunc =>
        (values: Record<string, any>) => {
            if (onChange) {
                const updates: Record<string, any> = {};
                if (typeof values !== 'function') {
                    Object.keys(values).forEach((e) => {
                        const key = !isMainTab(tabId) ? `${tabId}.${e}` : e;
                        updates[key] = values[e];
                    });
                } else {
                    updates[tabId] = values;
                }
                onChange(element, updates);
            }
        };

    const handleClose = () => {
        if (onClose) {
            onClose(element);
        }
    };

    const handleSave = () => {
        if (onSave) {
            onSave(element);
        }
    };

    const saveDisabled =
        !element.changed ||
        !isDataValid(element.data) ||
        (element.isNew && !element.permissions.create) ||
        (!element.isNew && !element.permissions.edit && !editByFieldPerm);

    const schemas = filterDict(visibleSchemas, (item) => !!item.title);

    return (
        <div className={styles.root}>
            <Typography variant='h6' style={{ flex: 1, textAlign: 'center' }}>
                {uiModel.meta.title} {element.data?.name?.value ? `"${element.data?.name?.value}"` : ''}
            </Typography>
            <div className={styles.buttons}>
                <div style={{ flex: 1 }} />
                {onSave && !readonly && (
                    <Button color={'secondary'} variant={'outlined'} disabled={saveDisabled} onClick={handleSave}>
                        {t('common:buttons.save')}
                    </Button>
                )}
                {onClose && !readonly && (
                    <Button color={'primary'} variant={'outlined'} onClick={handleClose}>
                        {t('common:buttons.close')}
                    </Button>
                )}
            </div>
            <Tabs value={currentTab} onChange={handleTabChange} indicatorColor='primary' textColor='primary'>
                {Object.keys(schemas).map((tabId) => {
                    const tabConf = uiModel.schema[tabId];
                    const valid = isDataValid(isMainTab(tabId) ? element.data : element.data[tabId] || {}, tabId);
                    return (
                        <Tab
                            style={{ color: !valid ? 'red' : undefined }}
                            key={tabId}
                            label={
                                <HelpButton
                                    label={tabConf.title}
                                    type={'window'}
                                    helpId={[uiModel.meta.collection, tabId]}
                                />
                            }
                            disabled={element.isNew && tabConf.disabled_for_new}
                            value={tabId}
                        />
                    );
                })}
            </Tabs>
            {Object.keys(schemas).map((tabId) => {
                const tabConf = uiModel.schema[tabId];
                let Component: any = null;
                if (tabConf.component) {
                    if (!tabConf.component || tabConf.component === 'default') {
                        Component = DataForm;
                    } else {
                        Component = getCustomComponent(tabConf.component);
                    }
                }
                if (Component) {
                    return element.isNew && tabConf.disabled_for_new ? null : (
                        <TabPanel key={tabId} value={currentTab} index={tabId} isNew={element.isNew}>
                            <Component
                                fieldPrefix={tabId}
                                schema={tabConf}
                                element={element}
                                onChange={handleDataChange(tabId)}
                                formState={formState}
                                updateFormState={(field: string, value: any) => {
                                    dispatch(updateFormStateAction(element.id, field, value));
                                }}
                            />
                        </TabPanel>
                    );
                }
                return null;
            })}
        </div>
    );
};

interface TabPanelProps {
    index: string;
    value: string;
    isNew: boolean;
}

function TabPanel(props: React.PropsWithChildren<TabPanelProps>) {
    const { children, value, index, isNew, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            {...other}
            style={{ height: `calc(100% - ${getToolbarTopMargin(2)}px)` }}>
            {/* render all tabs only for new element in order to run validation */}
            {(value === index || isNew) && (
                <Box p={2} style={{ height: '100%' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default DataFormTabs;
