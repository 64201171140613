import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { Store, applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { APThunkDispatch, AppState, GeneralAction } from './StoreTypes';
import { clearEditingElement } from './actions/DataActions';
import { dataReducer } from './reducers/DataReducer';
import { generalReducer } from './reducers/GeneralReducer';
import { notiferReduser } from './reducers/NotifierReducer';
import { statsReducer } from './reducers/StatsReducer';

export const browserHistory = createBrowserHistory();

const rootReducer = combineReducers({
    db: dataReducer,
    notifier: notiferReduser,
    general: generalReducer,
    stats: statsReducer,
    router: connectRouter(browserHistory),
});

const composeEnhancers =
    // @ts-ignore
    process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const thunkMiddleware = applyMiddleware<APThunkDispatch, AppState>(thunk);

export const store: Store<AppState, GeneralAction> = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(routerMiddleware(browserHistory), thunk.withExtraArgument({})), thunkMiddleware),
);

/**
 * Cut the editing elements
 */
setInterval(() => {
    const edits = store.getState().db.edits;
    const size = Object.keys(edits).length;
    if (size > 1000) {
        const sorted = Object.keys(edits)
            .filter((e) => !edits[e].changed)
            .sort((a, b) => {
                return edits[b].createTime - edits[a].createTime;
            });
        for (let i = 0; i < sorted.length && i < 10; i++) {
            store.dispatch(clearEditingElement(sorted[i]));
        }
    }
}, 10000);
