import { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/StoreTypes';
import { useTranslation } from 'react-i18next';
import { LightTooltip } from '../../Utils/LightTooltip';

type Props = {};

const ProjectStatsToolbar: FunctionComponent<Props> = (props: Props) => {
    const classes = useStyles();
    const projectStat = useSelector((state: AppState) => state.stats.projectStat);
    const { t } = useTranslation();
    if (projectStat?.activeCampaigns) {
        return (
            <div className={classes.root}>
                <LightTooltip arrow title={<span>{projectStat.activeCampaigns.map((e) => e.name).join('<br>')}</span>}>
                    <span className={classes.item}>
                        {t('forms:stats.project.activeCampaigns')} - {projectStat.activeCampaigns.length}
                    </span>
                </LightTooltip>
                <span className={classes.item}>
                    {t('forms:stats.project.usedOutTrunks')} - {projectStat.usedOutTrunks}
                </span>
            </div>
        );
    } else {
        return <div className={classes.root} />;
    }
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            textAlign: 'center',
            fontSize: '1.2rem',
            color: 'yellow',
        },
        item: {
            marginRight: '1em',
            padding: '0.5em',
        },
    }),
);

export default ProjectStatsToolbar;
