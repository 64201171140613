import { UIModelField, UIModelFieldType, UIModelSchema } from '@aatdev/common-types';
import BoolFilter from '@aatdev/reactdatagrid/BoolFilter';
import DateFilter from '@aatdev/reactdatagrid/DateFilter';
import NumberFilter from '@aatdev/reactdatagrid/NumberFilter';
import StringFilter from '@aatdev/reactdatagrid/StringFilter';
import { TypeColumn } from '@aatdev/reactdatagrid/types';
import { TypeSingleFilterValue } from '@aatdev/reactdatagrid/types/TypeFilterValue';
import moment from 'moment';
import objectPath from 'object-path';
import { ReactNode } from 'react';
import { fixedT } from '../../i18n';
import { DEFAULT_DATE_FORMAT, TABLE_NAME_FIELD } from '../../utils/Constants';
import { getDBElementName } from '../../utils/ModelUtils';
import EntityFilter from '../GridUtils/EntityFilter';
import SelectFilter from '../GridUtils/SelectFilter';

type FilterSettings = {
    filter?: any;
    props?: any;
    filterEditorProps?: any;
    render?: (...args: any[]) => ReactNode;
    type?: string;
    sortable?: boolean;
};

export const columnContextMenuPositions = [
    'tr-br',
    'tl-bl',
    'tr-tl',
    'tl-tr',
    'br-tr',
    'bl-tl',
    'br-tl',
    'bl-tr',
    'lc-tr',
    'rc-tl',
];

const resolveValue = (field: string, value: any, data: any) => {
    if (field.split('.').length > 1) {
        return objectPath.get(data, field);
    }

    if (field === TABLE_NAME_FIELD) {
        return getDBElementName(data);
    }
    return value;
};

const getColumnSettings = (field: UIModelField): FilterSettings => {
    const t = fixedT();
    switch (field.type) {
        case 'number':
            return {
                filter: NumberFilter,
                sortable: true,
                render: (args) => {
                    if (field.render) {
                        return field.render(args);
                    }
                    return resolveValue(field.field, args.value, args.cellProps.data);
                },
            };
        case 'date':
            return {
                filter: DateFilter,
                props: (props: any, args: { index: number }) => {
                    return {
                        dateFormat: DEFAULT_DATE_FORMAT,
                        triggerChangeOnTimeChange: true,
                        highlightWeekends: true,
                        weekNumbers: false,
                        cancelButton: false,
                        showClock: true,
                        locale: 'ru',
                        placeholder:
                            args.index === 1 ? t('common:table.date_is_before') : t('common:table.date_is_after'),
                    };
                },
                render: (args) => {
                    if (field.render) {
                        return field.render(args);
                    }
                    if (args.value) {
                        return moment(args.value).format(DEFAULT_DATE_FORMAT + ' HH:mm:ss');
                    }
                    return undefined;
                },
                sortable: true,
            };
        case 'boolean':
            return {
                filter: BoolFilter,
                sortable: true,
                render: (args) => {
                    if (field.render) {
                        return field.render(args);
                    }
                    return t(`common:table.${args?.value?.toString()}`);
                },
            };
        case 'entity':
            return {
                filter: EntityFilter,
                props: {
                    placeholder: field.title,
                    entityType: field.entity_type,
                    type: 'select',
                },
                render: ({ value, cellProps }) => {
                    return Array.isArray(value) ? value.map((e) => e.name).join(', ') : value?.name || '';
                },
                sortable: true,
            };
        case 'select':
            return {
                filter: SelectFilter,
                props: {
                    placeholder: field.title,
                    type: 'select',
                    dataSource: Object.keys(field.items).map((key) => ({
                        id: key,
                        label: field.items[key],
                    })),
                    multiple: false,
                },
                render: ({ value, cellProps }) => {
                    return Array.isArray(value)
                        ? value.map((e) => field.items[e]).join(', ')
                        : field.items[value] || '';
                },
                sortable: true,
            };
    }
    return {
        filter: StringFilter,
        render: (args) => {
            if (field.render) {
                return field.render(args);
            }
            return resolveValue(field.field, args.value, args.cellProps.data);
        },
    };
};

export const getDefaultFilterValues = (schema: UIModelSchema): Array<TypeSingleFilterValue> => {
    return schema.fields.map((field) => {
        switch (field.type) {
            case 'date':
                return {
                    name: field.field,
                    operator: 'afterOrOn',
                    type: 'date',
                    value: '',
                };
            case 'number':
                return {
                    name: field.field,
                    operator: 'gte',
                    type: 'number',
                    value: '',
                };
            case 'text':
                if (field.field === '_id') {
                    return {
                        name: field.field,
                        operator: 'eq',
                        type: 'string',
                        value: '',
                    };
                }
                return {
                    name: field.field,
                    operator: 'startsWith',
                    type: 'string',
                    value: '',
                };
            case 'entity':
                return {
                    name: field.field,
                    operator: 'startsWith',
                    type: 'string',
                    value: '',
                };
            case 'select':
                return {
                    name: field.field,
                    operator: 'startsWith',
                    type: 'string',
                    value: '',
                };
            case 'boolean':
                return {
                    name: field.field,
                    operator: 'eq',
                    type: 'boolean',
                    value: '',
                };
            default:
                return {
                    name: field.field,
                    operator: 'eq',
                    type: 'string',
                    value: '',
                };
        }
    });
};

export const getColumnsFromModel = (
    schema: UIModelSchema,
    type: 'table' | 'select',
    columnWidths: Record<string, number> = {},
): Array<TypeColumn> => {
    return schema.fields.map((field) => {
        const filter = getColumnSettings(field);
        const filterable = !field.transient;
        return {
            name: field.field,
            header: field.title,
            defaultFlex: columnWidths[field.field] || 1,
            sortable: filter.sortable,
            lockable: false,
            defaultVisible: type === 'table' ? field.show_in_table || false : field.show_in_select || false,
            filterable: filterable,
            filterEditor: filter.filter,
            filterEditorProps: filter.props,
            render: filter.render,
            type: field.type,
            maxWidth: field.maxWidth,
        };
    });
};

export const createFieldConfig = (type: UIModelFieldType, title: string, field: string, settings: any = {}) => {
    return {
        title: title,
        field: field,
        type: type,
        show_in_table: true,
        fast_searchable: true,
        ...settings,
    };
};
