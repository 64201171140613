import React, { FunctionComponent, useEffect } from 'react';
import { WithUIModelProps } from '../../utils/WithUIModel';
import { makeStyles, Paper } from '@material-ui/core';
import { getToolbarTopMargin } from '../../utils/AppTheme';
import { loadEditingElement, saveElementThunk, updatePartEditingElementAction } from '../../store/actions/DataActions';
import { useDispatch } from 'react-redux';
import DataFormTabs from './DataForm/DataFormTabs';
import { EditingElement } from '../../store/actions/DataActionTypes';
import { useEditableElement } from '../../utils/UseEditableElement';

type Props = WithUIModelProps;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        margin: 'auto',
        padding: '1em',
        minHeight: `calc(100vh - ${getToolbarTopMargin(2)}px)`,
    },
});

const DataFormEditor: FunctionComponent<Props> = (props: Props) => {
    const { uiModel } = props;
    const styles = useStyles();
    const dispatch = useDispatch();
    let element: EditingElement = useEditableElement(uiModel.meta.fromElementId!);
    useEffect(() => {
        dispatch(loadEditingElement(uiModel.meta.collection, uiModel.meta.fromElementId!));
    }, [uiModel.meta.fromElementId]);
    if (!element) {
        return <span />;
    }
    const handleDataChange = (element: EditingElement, values: Record<string, any>) => {
        dispatch(updatePartEditingElementAction(element.id, values));
    };

    const handleSave = (element: EditingElement) => {
        dispatch(saveElementThunk(element));
    };
    return (
        <Paper className={styles.root}>
            <DataFormTabs uiModel={uiModel} element={element} onChange={handleDataChange} onSave={handleSave} />
        </Paper>
    );
};

export default DataFormEditor;
