import { ElementWithDeps } from '@aatdev/common-types';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { backend } from '../../data/Backend';
import { BaseDbCollection } from '../../data/BaseDbCollection';
import { clearTableState } from '../../store/actions/DataActions';
import { AppState } from '../../store/StoreTypes';
import CommonDataTable from './CommonDataTable';

type Props = {
    title: string;
    collection: string | BaseDbCollection;
    open: boolean;
    onSelect: (element: ElementWithDeps<any>) => void;
    onClose: () => void;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    notClosable?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: 600,
        },
    }),
);

const CommonTableSelect: FunctionComponent<Props> = (props: Props) => {
    const { collection, title, open, onSelect, maxWidth, onClose, notClosable } = props;
    const tableId = useRef('');
    const loadedModels = useSelector((state: AppState) => state.db.loadedModels);
    const styles = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (loadedModels[collection]) {
            tableId.current = `${uiModel.meta.collection}.${v4()}`;
        }
        return () => {
            dispatch(clearTableState(tableId.current));
        };
    }, [collection, loadedModels]);

    if (!loadedModels[collection]) {
        return <span />;
    }

    const uiModel = {
        ...loadedModels[collection],
        meta: {
            ...loadedModels[collection].meta,
            title: title,
            tableId: tableId.current,
        },
    };

    const handleClose = () => {
        if (!notClosable) {
            onClose();
        }
    };

    return (
        <Dialog open={open} maxWidth={maxWidth || 'md'} fullWidth={true} onClose={handleClose}>
            <DialogContent>
                <CommonDataTable
                    type={'select'}
                    className={styles.table}
                    checkBoxColumn={false}
                    uiModel={uiModel}
                    refreshOnOpen={open}
                    onDblClick={(element) => {
                        backend.getById(collection, element._id, -1).then((res) => {
                            if (res.data) {
                                onSelect(res.data);
                            } else {
                                throw new Error(`Not found ${collection} = ${element._id}`);
                            }
                        });
                    }}
                />
            </DialogContent>
            <DialogActions>
                {!notClosable && (
                    <Button autoFocus onClick={onClose} color='primary'>
                        Закрыть
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default CommonTableSelect;
