import { ExpressionSetTypeValidated, ExpressionTypeValidated } from './ExpressionEditorTypes';
import { IconButton } from '@material-ui/core';
import { AddOutlined as RuleIcon, PlaylistAddOutlined as SetIcon } from '@material-ui/icons';
import { v4 } from 'uuid';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { createValidateValue } from '../../utils/ValidatedValueUtils';

type ExpressionSetProps = {
    value: ExpressionSetTypeValidated;
    onChange: (path: (string | number)[], value: any) => void;
    onAdd: (path: (string | number)[], value: ExpressionTypeValidated) => void;
    path: (string | number)[];
    readonly: boolean;
};

const ExpressionSet: React.FC<ExpressionSetProps> = (props) => {
    const { value, onChange, onAdd, path, readonly } = props;
    const styles = useStyles();

    const handleOpChange = (op: string) => {
        onChange([...path, 'op'], createValidateValue(op, true));
    };

    return (
        <div className={styles.container}>
            <ToggleButtonGroup
                size='small'
                exclusive
                value={value.op.value}
                onChange={(event, op) => handleOpChange(op)}>
                <ToggleButton value='and' style={{ padding: 2 }} disabled={readonly}>
                    and
                </ToggleButton>
                <ToggleButton value='or' style={{ padding: 2 }} disabled={readonly}>
                    or
                </ToggleButton>
            </ToggleButtonGroup>
            <div className={styles.buttons}>
                <IconButton
                    size={'small'}
                    color={'primary'}
                    disabled={readonly}
                    onClick={() =>
                        onAdd(path, {
                            type: createValidateValue('rule', true),
                            id: createValidateValue(v4(), true),
                            value: createValidateValue(''),
                        })
                    }>
                    <RuleIcon />
                </IconButton>
                <IconButton
                    size={'small'}
                    color={'primary'}
                    disabled={readonly}
                    onClick={() =>
                        onAdd(path, {
                            type: createValidateValue('set', true),
                            id: createValidateValue(v4(), true),
                            op: createValidateValue('and', true),
                            inner: [],
                        })
                    }>
                    <SetIcon />
                </IconButton>
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
        },
        buttons: {
            flex: 1,
            textAlign: 'right',
        },
    }),
);

export default ExpressionSet;
