import { SearchResult, UIModelMeta, UIModels } from '@aatdev/common-types';
import objectPath from 'object-path';
import { getCustomComponent } from '../../components/Custom';
import DataEditor from '../../components/DataEditor/DataEditor';
import { DataFormOnChangeData } from '../../components/DataEditor/DataForm/DataFormTypes';
import { ProjectModel } from '../../data/models/ProjectModel';
import { fixedT } from '../../i18n';
import LocalStoreService from '../../services/LocalStoreService';
import { MAIN_TAB_ID } from '../../utils/Constants';
import { getRoutePath } from '../../utils/MenuUtils';
import { isFieldVisible, updateInnerField } from '../../utils/ModelUtils';
import { deleteValidatedData, getDataHash } from '../../utils/ValidatedValueUtils';
import {
    AppMenuItemsType,
    BlockedNavigateData,
    DataAction,
    DataActionType,
    DbState,
    EditingElement,
    LoadModelsResponse,
    QueryState,
    QueryStateFields,
    TableChainFields,
    TableChainState,
    TableState,
    TableStateFields,
    TableSubMenuItem,
} from '../actions/DataActionTypes';
import { updateTableStateField } from '../actions/DataActionUtils';

const getSavedTableState = () => {
    const saved = LocalStoreService.getAsObject('persisted-table-state', {});
    return Object.keys(saved).reduce((previousValue: Record<string, TableState>, currentValue) => {
        if (Object.keys(saved[currentValue]).filter((e) => typeof saved[currentValue][e] === 'object').length > 0) {
            previousValue[currentValue] = saved[currentValue];
        }
        return previousValue;
    }, {});
};

const initState = (): DbState => {
    return {
        loadedModels: {},
        tableQuery: {},
        tableState: getSavedTableState(),
        tableData: {},
        tableChain: {},
        formState: {},
        menuItems: [],
        edits: {},
    };
};

const initMenu = (state: DbState, action: DataAction<LoadModelsResponse>): DbState => {
    const { models, menu } = action.payload;
    const t = fixedT();
    const routes: AppMenuItemsType = menu.flatMap((item, index) => {
        return item.items.map((menuName) => {
            if (typeof menuName === 'string') {
                const model = models[menuName];
                const meta: UIModelMeta = {
                    ...model.meta,
                    tableId: model.meta.collection,
                };
                return {
                    type: 'table',
                    root: true,
                    id: meta.tableId,
                    order: index,
                    group: t(item.title),
                    path: getRoutePath(meta.tableId),
                    title: model.meta.title,
                    component: DataEditor,
                    uiModel: {
                        meta: meta,
                        schema: model.schema,
                    },
                };
            } else {
                return {
                    id: menuName.component,
                    type: 'table',
                    root: true,
                    group: t(item.title),
                    path: getRoutePath(menuName.component),
                    title: t(menuName.title),
                    component: getCustomComponent(menuName.component),
                    uiModel: {} as any,
                };
            }
        });
    }, {});
    return {
        ...state,
        menuItems: routes,
    };
};

const loadModels = (state: DbState, action: DataAction<UIModels>): DbState => {
    const loadedModels = action.payload;
    Object.keys(loadedModels).forEach((key) => {
        loadedModels[key].meta.tableId = `${loadedModels[key].meta.collection}`;
    });
    return {
        ...state,
        loadedModels,
    };
};

const updateTableQueryField = <T extends QueryStateFields>(
    state: DbState,
    action: DataAction<{ modelMeta: UIModelMeta; field: T; data: QueryState[T] }>,
): DbState => {
    const { field, modelMeta, data } = action.payload;
    return updateTableStateField(state, 'tableQuery', [modelMeta.tableId, field], data);
};

const updateTableVariablesField = <T extends TableStateFields>(
    state: DbState,
    action: DataAction<{ modelMeta: UIModelMeta; field: T; data: TableState[T] }>,
): DbState => {
    const { field, modelMeta, data } = action.payload;
    let newState: DbState;
    // merge column widths
    if (field === 'columnWidths') {
        const update: any = {
            ...(state.tableState[modelMeta.tableId]?.columnWidths || {}),
            ...(data as any),
        };
        newState = updateTableStateField(state, 'tableState', [modelMeta.tableId, field], update);
    } else {
        newState = updateTableStateField(state, 'tableState', [modelMeta.tableId, field], data);
    }
    LocalStoreService.set('persisted-table-state', JSON.stringify(newState.tableState));
    return newState;
};

const updateTableChainField = <T extends TableChainFields>(
    state: DbState,
    action: DataAction<{ chainId: string; field: T; data: TableChainState[T] }>,
): DbState => {
    const { field, chainId, data } = action.payload;
    return updateTableStateField(state, 'tableChain', [chainId, field], data);
};

const updateTableData = (state: DbState, action: DataAction<{ tableId: string; data: SearchResult }>): DbState => {
    return updateTableStateField(state, 'tableData', [action.payload.tableId], action.payload.data);
};

const updateFormState = (
    state: DbState,
    action: DataAction<{ elementId: string; field: string; value: any }>,
): DbState => {
    const { field, value, elementId } = action.payload;
    if (state.formState[elementId] !== value) {
        return updateTableStateField(state, 'formState', [elementId, field], value);
    }
    return state;
};

const addSubMenu = (
    state: DbState,
    action: DataAction<{ menu: TableSubMenuItem; element: EditingElement }>,
): DbState => {
    const { menu, element } = action.payload;
    if (state.menuItems.findIndex((e) => e.id === menu.id) > -1) {
        return state;
    }
    return {
        ...state,
        menuItems: [...state.menuItems, menu],
        edits: {
            ...state.edits,
            [element.id]: element,
        },
    };
};

const closeSubMenu = (state: DbState, action: DataAction<string>): DbState => {
    const { payload: elementId } = action;
    const index = state.menuItems.findIndex((e) => e.id === elementId);
    if (index > -1) {
        const edits = { ...state.edits };
        delete edits[elementId];
        const filterByPrefix = (dict: any) => {
            const res: any = {};
            Object.keys(dict).forEach((key) => {
                if (!key.startsWith(elementId)) {
                    res[key] = dict[key];
                }
            });
            return res;
        };
        return {
            ...state,
            menuItems: [...state.menuItems.slice(0, index), ...state.menuItems.slice(index + 1)],
            tableData: filterByPrefix(state.tableData),
            tableState: filterByPrefix(state.tableState),
            tableQuery: filterByPrefix(state.tableQuery),
            tableChain: filterByPrefix(state.tableChain),
            edits,
        };
    }
    return state;
};

/**
 * Replace editing element
 * @param state
 * @param action
 */
const updateEntireEditingElement = (state: DbState, action: DataAction<EditingElement>): DbState => {
    const { payload: element } = action;
    return {
        ...state,
        edits: {
            ...state.edits,
            [element.id]: element,
        },
    };
};

/**
 * Delete editing element from edit states if there is no menu that refers to it
 * @param state
 * @param action
 */
const clearEditingElement = (state: DbState, action: DataAction<string>): DbState => {
    const { payload: elementId } = action;
    const edits = { ...state.edits };
    if (state.menuItems.findIndex((e) => e.id === elementId) === -1) {
        delete edits[elementId];
        return {
            ...state,
            edits,
        };
    }
    return state;
};

/**
 * Update part of editing element not saving it in the db
 * @param state
 * @param action
 */
const updatePartEditingElement = (
    state: DbState,
    action: DataAction<{ elementId: string; updates: DataFormOnChangeData }>,
): DbState => {
    const { updates, elementId } = action.payload;
    const element = state.edits[elementId];
    let data = { ...element.data };
    let updated = false;

    if (!element) {
        return state;
    }

    Object.keys(updates).forEach((key) => {
        if (typeof updates[key] === 'function') {
            const oldHash = getDataHash(data[key]);
            const newValue = updates[key](data[key]);
            updated = updated || oldHash !== getDataHash(newValue);
            data[key] = newValue;
            element.updatedFields[key] = true;
        } else {
            const oldValue = getDataHash(objectPath.get(data, key.split('.')));
            data = updateInnerField(data, key.split('.'), updates[key]);
            const newValue = objectPath.get(data, key.split('.'));
            updated = updated || oldValue !== getDataHash(newValue);
            if (updated) {
                element.updatedFields[key.split('.')[0]] = true;
            }
        }
    });

    const updatedElement: EditingElement = {
        ...element,
        data: data,
        changed: updated || element.changed,
    };

    const dataNoValidation = deleteValidatedData(data);

    let tableData: any = state.tableData;
    const items = state.tableData[element.uiModel.meta.collection]?.items;
    const index = items?.findIndex((e) => e._id === elementId);
    // update table element
    if (index > -1) {
        tableData = {
            ...state.tableData,
            [element.uiModel.meta.collection]: {
                ...state.tableData[element.uiModel.meta.collection],
                items: [...items.slice(0, index), dataNoValidation, ...items.slice(index + 1)],
            },
        };
    }

    // make all hidden fields valid
    Object.keys(element.uiModel.schema).forEach((e) => {
        const schema = element.uiModel.schema[e];
        schema?.fields?.forEach((f) => {
            if (
                (schema.show_conditions && !isFieldVisible(dataNoValidation, schema)) ||
                (f.show_conditions && !isFieldVisible(dataNoValidation, f))
            ) {
                const fields: string[] = [];
                if (e !== MAIN_TAB_ID) {
                    fields.push(e);
                }
                fields.push(f.field);
                fields.push('___valid___');
                objectPath.set(data, fields, true);
            }
        });
    });

    return {
        ...state,
        edits: {
            ...state.edits,
            [elementId]: updatedElement,
        },
        tableData: tableData,
    };
};

/**
 * Used to replace menu after an element was added
 * @param state
 * @param action
 */
const replaceMenu = (state: DbState, action: DataAction<{ oldMenuId: string; element: EditingElement }>): DbState => {
    const { oldMenuId, element } = action.payload;
    const index = state.menuItems.findIndex((e) => e.id === oldMenuId);
    if (index === -1) {
        return state;
    }
    const edits = { ...state.edits };
    delete edits[oldMenuId];
    edits[element.id] = element;
    const menuItems = [...state.menuItems];
    const updatedMenu = { ...menuItems[index] };
    if (updatedMenu.type === 'sub') {
        updatedMenu.id = element.id;
        updatedMenu.elementId = element.id;
        updatedMenu.path = getRoutePath(element.uiModel.meta.tableId, element.id);
    }
    menuItems[index] = updatedMenu;
    return {
        ...state,
        menuItems,
        edits,
    };
};

const blockNavigate = (state: DbState, action: DataAction<BlockedNavigateData>): DbState => {
    return {
        ...state,
        blockedNavigate: action.payload,
    };
};

const clearTableState = (state: DbState, action: DataAction<string>): DbState => {
    const { payload: tableId } = action;
    const updated: DbState = {
        ...state,
        tableQuery: {
            ...state.tableQuery,
        },
        tableData: {
            ...state.tableData,
        },
    };
    delete updated.tableQuery[tableId];
    delete updated.tableData[tableId];
    return updated;
};

const selectProject = (state: DbState, action: DataAction<ProjectModel>): DbState => {
    return {
        ...state,
        currentProject: action.payload,
        tableData: {},
        tableQuery: {},
        formState: {},
        edits: {},
        menuItems: state.menuItems.filter((value) => value.type === 'table'),
    };
};

export const dataReducer = (state: DbState = initState(), action: DataAction<any>): DbState => {
    switch (action.type) {
        case DataActionType.LOAD_ALL_MODELS:
            return loadModels(state, action);
        case DataActionType.UPDATE_TABLE_QUERY_FIELD:
            return updateTableQueryField(state, action);
        case DataActionType.UPDATE_TABLE_VARIABLE:
            return updateTableVariablesField(state, action);
        case DataActionType.UPDATE_TABLE_DATA_FIELD:
            return updateTableData(state, action);
        case DataActionType.UPDATE_TABLE_CHAIN_FIELD:
            return updateTableChainField(state, action);
        case DataActionType.UPDATE_FORM_STATE:
            return updateFormState(state, action);
        case DataActionType.INIT_MENU:
            return initMenu(state, action);
        case DataActionType.ADD_SUB_MENU:
            return addSubMenu(state, action);
        case DataActionType.CLOSE_SUB_MENU:
            return closeSubMenu(state, action);
        case DataActionType.UPDATE_EDITING_ELEMENT:
            return updateEntireEditingElement(state, action);
        case DataActionType.CLEAR_EDITING_ELEMENT:
            return clearEditingElement(state, action);
        case DataActionType.REPLACE_MENU:
            return replaceMenu(state, action);
        case DataActionType.BLOCK_NAVIGATE:
            return blockNavigate(state, action);
        case DataActionType.UPDATE_PART_EDITING_ELEMENT:
            return updatePartEditingElement(state, action);
        case DataActionType.CLEAR_TABLE_STATE:
            return clearTableState(state, action);
        case DataActionType.SELECT_PROJECT:
            return selectProject(state, action);
        default:
            return state;
    }
};
