import React, { FunctionComponent } from 'react';
import { WithUIModelProps } from '../../utils/WithUIModel';
import DataTable from './DataTable';
import { makeStyles } from '@material-ui/core';
import { getToolbarTopMargin } from '../../utils/AppTheme';

type Props = WithUIModelProps;

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        minHeight: `calc(100vh - ${getToolbarTopMargin(3)}px)`,
    },
});

const DataTableEditor: FunctionComponent<Props> = (props: Props) => {
    const { uiModel } = props;
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <DataTable uiModel={uiModel} />
        </div>
    );
};

export default DataTableEditor;
