import { ExpressionType, FilterExpressionType, UIModelFieldType } from '@aatdev/common-types';
import {
    DialerCallingListModel,
    DialerCollections,
    DialerSelectionModel,
    DialerUploadedListModel,
} from '@aatdev/dialer-types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import { useFindById } from '../../../data/UseQuery';
import { getToolbarTopMargin } from '../../../utils/AppTheme';
import { cloneModel } from '../../../utils/ModelUtils';
import { useModel } from '../../../utils/UseModel';
import CommonDataTable from '../../CommonDataTable/CommonDataTable';
import { createFieldConfig } from '../../CommonDataTable/CommonDataTableUtils';
import { PreconfiguredDataTableProps } from '../../CommonDataTable/PreconfiguredDataTable/PreconfiguredDataTableTypes';

type Props = PreconfiguredDataTableProps<{
    uploadedList?: DialerUploadedListModel;
    selection?: DialerSelectionModel;
    callingList?: DialerCallingListModel;
    additinalFields?: string[];
    excludedFields?: string[];
}>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            height: `calc(100vh - ${getToolbarTopMargin(5)}px)`,
        },
    }),
);

const UploadedPhoneListTable: FunctionComponent<Props> = (props) => {
    const { filters, tableId, params, onSelect, breadcrumbs } = props;
    const {
        uploadedList,
        selection,
        callingList: paramCallingList,
        additinalFields = [],
        excludedFields = [],
    } = params || {};
    const styles = useStyles();
    const origModel = useModel(DialerCollections.uploaded_list_phone);
    const callingListId =
        selection?.calling_list_id?._id || uploadedList?.calling_list_id?._id || paramCallingList?._id || '';
    const callingList: DialerCallingListModel = useFindById(DialerCollections.calling_list, callingListId)?.data;

    if (!origModel || (callingListId && !callingList)) {
        return <span />;
    }

    const fields = [
        ...additinalFields,
        '_id',
        'uploaded_list_id',
        'create_date',
        'state',
        'is_valid',
        'last_call_state',
        'last_call_cause',
        'message',
    ].filter((e) => excludedFields.findIndex((f) => f === e) === -1);

    const uiModel = cloneModel(origModel, fields);
    uiModel.meta.delete_if_deps = false;
    uiModel.meta.tableId = tableId;

    const ff = filters || {
        filters: [],
    };

    if (callingList) {
        callingList.structure.forEach((s, index) => {
            uiModel.schema.main.fields.push(
                createFieldConfig(
                    s.type === 'number' ? UIModelFieldType.number : UIModelFieldType.text,
                    s.name,
                    `data.${index}`,
                    {
                        fast_searchable: true,
                        show_in_table: true,
                    },
                ),
            );
        });
    }

    if (uploadedList) {
        ff.filters.push({
            type: 'string',
            value: uploadedList._id,
            operator: 'eq',
            name: 'uploaded_list_id',
        });
    }

    if (callingList) {
        ff.filters.push({
            type: 'string',
            value: callingList._id,
            operator: 'eq',
            name: 'calling_list_id',
        });
    }

    if (selection && callingList) {
        const structure = callingList.structure;
        const fill = (expr: ExpressionType): FilterExpressionType | undefined => {
            if (expr.type === 'set') {
                return {
                    type: 'set',
                    op: expr.op,
                    // @ts-ignore
                    inner: expr.inner.map((e) => fill(e)).filter((e) => e !== undefined),
                };
            } else {
                const idx = structure.findIndex((e) => e.id === expr.field);
                if (idx > -1) {
                    const s = structure[idx];
                    return {
                        type: 'rule',
                        filter: {
                            type: s.type === 'phone' ? 'string' : (s.type as any),
                            operator: expr.operand as any,
                            name: `data.${idx}`,
                            value: expr.value,
                        },
                    };
                }
                return undefined;
            }
        };

        ff.expression = fill(selection.rules);
    }

    uiModel.meta.tableId = tableId;

    return (
        <>
            <CommonDataTable
                type={'table'}
                className={styles.table}
                uiModel={uiModel}
                tableButtons={{}}
                toolbar={breadcrumbs}
                filters={ff}
                previewMode={true}
                readonlyPreview={true}
                onDblClick={(element) => {
                    if (onSelect) {
                        onSelect(element);
                    }
                }}
            />
        </>
    );
};

export default UploadedPhoneListTable;
