import { UIModel, UIModelField, UIModelSchema } from '@aatdev/common-types';
import cloneDeep from 'lodash.clonedeep';
import objectPath from 'object-path';
import * as objectPathIm from 'object-path-immutable';
import { fixedT } from '../i18n';

export const updateInnerField = (object: any, fieldArray: any[], value: any) => {
    return objectPathIm.set(object, fieldArray, value);
};

export const deleteInnerField = (object: any, fieldArray: any[]) => {
    return objectPathIm.del(object, fieldArray);
};

export const pushField = (object: any, fieldArray: any[], value: any, sort?: (a: any, b: any) => number) => {
    const res = objectPathIm.push(object, fieldArray, value);
    if (sort) {
        const arr = [...objectPathIm.get(res, fieldArray)];
        return objectPathIm.set(res, fieldArray, arr.sort(sort));
    }
    return res;
};

export const deleteFromArray = (array: any[], value: any, key = 'id') => {
    const index = array.findIndex((e) => e[key] === value || e[key]?.value === value);
    if (index === -1) {
        return array;
    }
    return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const cloneModel = (model: UIModel, keepFields?: string[]) => {
    const result = cloneDeep(model);
    if (keepFields) {
        const fields: UIModelField[] = [];
        result.schema.main.fields.forEach((field) => {
            if (keepFields.findIndex((name) => name === field.field) > -1) {
                fields.push(field);
            }
        });
        result.schema.main.fields = fields;
    }
    return result;
};

export const isFieldVisible = (data: any, field: UIModelField | UIModelSchema, updatedData: any = {}) => {
    data = {
        ...data,
        ...updatedData,
    };
    if (field.show_conditions) {
        for (const key of Object.keys(field.show_conditions)) {
            if (field.show_conditions[key] != objectPath.get(data, key.split('.'))) {
                return false;
            }
        }
    }
    return true;
};

export const getDBElementName = (data: any) => {
    if (data.archived) {
        const t = fixedT();
        return `${data.name} (${t('common:table.archived')})`;
    }
    return data.name;
};
