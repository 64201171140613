import {
    ChangeFuncType,
    ExpressionPathType,
    ExpressionSetTypeValidated,
    ExpressionTypeValidated,
} from './ExpressionEditorTypes';
import ExpressionRule from './ExpressionRule';
import ExpressionSet from './ExpressionSet';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import clsx from 'clsx';
import { ReduxStateChangeFunc } from '../../store/actions/DataActionTypes';

type ExpressionEditorProps = {
    value: ExpressionTypeValidated;
    onChange: ReduxStateChangeFunc;
    renderEditor: ChangeFuncType;
    readonly: boolean;
};

const shiftWidth = 15;
const rowHeight = 70;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            position: 'relative',
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
            padding: 3,
            height: rowHeight,
        },
        innerRow: {
            '&::before': {
                position: 'absolute',
                border: '1px solid gray',
                content: '""',
                marginLeft: -shiftWidth * 2,
                width: shiftWidth * 2,
                top: '50%',
            },
        },
        firstRow: {
            '&::before': {
                position: 'absolute',
                border: '1px solid gray',
                content: '""',
                marginLeft: -shiftWidth,
                width: shiftWidth,
                top: '50%',
            },
        },
        editor: {
            flex: 1,
            alignSelf: 'center',
        },
    }),
);

const resolvePath = (path: ExpressionPathType) => {
    const result: string[] = [];
    path.forEach((value) => {
        if (typeof value === 'number') {
            result.push('inner');
            result.push(value.toString());
        } else {
            result.push(value);
        }
    });
    return result.join('.');
};

const ExpressionEditor: React.FC<ExpressionEditorProps> = (props) => {
    const { onChange, value, renderEditor, readonly } = props;

    const handleChange = (path: ExpressionPathType, value: any) => {
        onChange(resolvePath(path), value, 'update');
    };

    const handleAdd = (path: ExpressionPathType, value: ExpressionTypeValidated) => {
        onChange(resolvePath([...path, 'inner']), value, 'add');
    };

    const handleDelete = (path: ExpressionPathType) => {
        onChange(resolvePath(path), undefined, 'delete');
    };

    const components: any[] = [];
    const levels: number[] = [];

    const fillComponents = (expr: ExpressionTypeValidated, path: (string | number)[]) => {
        if (!expr) {
            return;
        }
        levels.push(path.length);
        if (expr.type.value === 'rule') {
            components.push(
                <ExpressionEditorRow
                    key={expr.id.value}
                    onDelete={handleDelete}
                    path={path}
                    type={expr.type.value}
                    readonly={readonly}
                    index={levels.length - 1}
                    levels={levels}>
                    <ExpressionRule value={expr} renderEditor={renderEditor} onChange={handleChange} path={path} />
                </ExpressionEditorRow>,
            );
        }
        if (expr.type.value === 'set') {
            const set = expr as ExpressionSetTypeValidated;
            components.push(
                <ExpressionEditorRow
                    key={expr.id.value}
                    onDelete={handleDelete}
                    path={path}
                    type={expr.type.value}
                    readonly={readonly}
                    index={levels.length - 1}
                    levels={levels}>
                    <ExpressionSet
                        value={set}
                        path={path}
                        onAdd={handleAdd}
                        onChange={handleChange}
                        readonly={readonly}
                    />
                </ExpressionEditorRow>,
            );
            set.inner.forEach((v, index) => {
                fillComponents(v, [...path, index]);
            });
        }
    };

    fillComponents(value, []);

    return <div>{components}</div>;
};

type ExpressionEditorRowProps = {
    path: ExpressionPathType;
    onDelete: (path: ExpressionPathType) => void;
    type: 'set' | 'rule';
    index: number;
    levels: number[];
    readonly: boolean;
};

const ExpressionEditorRow: React.FC<ExpressionEditorRowProps> = (props) => {
    const { children, onDelete, path, index, levels, readonly } = props;
    const styles = useStyles();

    let mult = 1;
    for (let i = index - 1; i > 0; i -= 1) {
        if (levels[index] > levels[i]) {
            break;
        }
        if (levels[i] === path.length) {
            mult = index - i;
            break;
        }
    }

    return (
        <div
            className={clsx(styles.row, path.length > 0 ? styles.innerRow : styles.firstRow)}
            style={{ marginLeft: shiftWidth * path.length }}>
            {/* vertical line */}
            {path.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        border: '1px solid gray',
                        marginLeft: -shiftWidth * 2,
                        height: rowHeight * mult,
                        bottom: rowHeight / 2,
                    }}
                />
            )}
            <div className={styles.editor}>{children}</div>
            <IconButton
                size={'small'}
                onClick={() => onDelete(path)}
                color={'secondary'}
                disabled={path.length === 0 || readonly}>
                <DeleteOutline />
            </IconButton>
        </div>
    );
};

export default ExpressionEditor;
