type LocalStoreKeys =
    | 'drawer-open'
    | 'drawer-width'
    | 'current-project-id'
    | 'persisted-table-state'
    | 'table-area-width'
    | 'i18nextLng';

const prefix = 'dialer';

class LocalStoreService {
    set(key: LocalStoreKeys, value: any) {
        localStorage.setItem(`${prefix}-${key}`, value.toString());
    }

    get(key: LocalStoreKeys, def?: string) {
        return localStorage.getItem(`${prefix}-${key}`) || def;
    }

    getAsObject(key: LocalStoreKeys, def: object) {
        const val = localStorage.getItem(`${prefix}-${key}`);
        if (val) {
            return JSON.parse(val);
        }
        return def;
    }

    getAsNumber(key: LocalStoreKeys, def: number) {
        return +(localStorage.getItem(`${prefix}-${key}`) || def);
    }

    getAsBoolean(key: LocalStoreKeys, def: boolean) {
        const val = localStorage.getItem(`${prefix}-${key}`);
        if (val) {
            return val === 'true';
        }
        return def;
    }
}

export default new LocalStoreService();
