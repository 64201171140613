import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store/StoreTypes';
import { NavigateOptions, useLocation, useNavigate } from 'react-router';
import { blockNavigateAction } from '../store/actions/DataActions';

export type BlockNavigateFunction = (to: string, options?: NavigateOptions, goAction?: () => void) => void;

/**
 * An wrapper around navigate function
 * It checks whether exists changes in the form on the current location
 * If there are changes it set a global state which checked n the App
 * The App shows a confirm dialog and if the answer = yes it invokes the goAction
 */
export const useBlockNavigate = (): BlockNavigateFunction => {
    const navigate = useNavigate();
    const edits = useSelector((state: AppState) => state.db.edits);
    const dispatch = useDispatch();
    const location = useLocation();

    return (to: string, options?: NavigateOptions, goAction?: () => void) => {
        const args = location.pathname.split('/');
        const element = edits[args[args.length - 1]];
        if (element && element.changed && goAction) {
            dispatch(
                blockNavigateAction({
                    goAction: () => {
                        navigate(to);
                        if (goAction) goAction();
                    },
                }),
            );
        } else {
            navigate(to, options);
            if (goAction) goAction();
        }
    };
};
