import { expressionOperandsByType, UIModelFieldSelectItem, UIModelFieldType } from '@aatdev/common-types';
import { DialerCollections, DialerStructureItem, DialerStructureItemType } from '@aatdev/dialer-types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { useFindById } from '../../../data/UseQuery';
import { createReduxStateChangeFunc } from '../../../store/actions/DataActionUtils';
import { createValidateValue } from '../../../utils/ValidatedValueUtils';
import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import DataInput from '../../DataEditor/DataForm/DataInput';
import ExpressionEditor from '../../ExpressionEditor/ExpressionEditor';
import { RuleItemValidated } from '../Data/SelectionTypes';

type SelectionRulesV2Props = {} & DataFormProps;

const SelectionRulesV2: React.FC<SelectionRulesV2Props> = (props) => {
    const { onChange, element, fieldPrefix } = props;
    const { data, permissions } = element;
    const callingListId = data.calling_list_id?.value?._id;
    const listStructure: DialerStructureItem[] = useFindById<any>(DialerCollections.calling_list, callingListId)?.data
        ?.structure;
    const tabData = data[fieldPrefix];

    useEffect(() => {
        if (!tabData) {
            onChange(() => ({
                id: createValidateValue(v4(), true),
                type: createValidateValue('set', true),
                op: createValidateValue('and', true),
                inner: [],
            }));
        }
    }, []);

    if (!listStructure || !tabData) return <span />;

    const renderEditor = (value: any, onChange: (field: string, value: any) => void) => {
        return (
            <ItemEditor
                item={value}
                callingListId={callingListId}
                structure={listStructure}
                onChange={onChange}
                disabled={!permissions.edit}
                helpPrefix={[element.uiModel.meta.collection, fieldPrefix]}
            />
        );
    };

    const handleChange = createReduxStateChangeFunc(onChange);

    return (
        <ExpressionEditor
            renderEditor={renderEditor}
            value={tabData}
            onChange={handleChange}
            readonly={!permissions.edit}
        />
    );
};

type ItemEditorProps = {
    item: RuleItemValidated;
    onChange: (field: string, value: any) => void;
    structure: DialerStructureItem[];
    callingListId: string;
    disabled?: boolean;
    helpPrefix?: string[];
};

const ItemEditor: FunctionComponent<ItemEditorProps> = (props: ItemEditorProps) => {
    const { item, onChange, disabled, structure, callingListId, helpPrefix = [] } = props;
    const styles = useStyles();
    const { t } = useTranslation();
    const structureItem = structure.find((s) => s.id === item.field?.value);
    const type =
        structureItem?.type === DialerStructureItemType.contactId
            ? DialerStructureItemType.string
            : structureItem?.type;
    const operand =
        structureItem && type ? expressionOperandsByType[type].find((s) => s.value === item.operand?.value) : undefined;
    return (
        <div className={styles.editor}>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type: UIModelFieldType.select,
                        title: t('tables:selection.tabs.rules.fields.field.title'),
                        field: 'type',
                        disableClearable: true,
                        items: structure.reduce((pv: Record<string, UIModelFieldSelectItem>, cv) => {
                            pv[cv.id] = {
                                label: cv.name,
                            };
                            return pv;
                        }, {}),
                    }}
                    uuid={callingListId}
                    fullWidth={true}
                    constrains={'notEmpty'}
                    value={structureItem ? item.field : createValidateValue('')}
                    disabled={disabled}
                    onChange={(value) => onChange('field', value)}
                    helpId={[...helpPrefix, 'field']}
                />
            </div>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type: UIModelFieldType.select,
                        title: t('tables:selection.tabs.rules.fields.operand.title'),
                        field: 'type',
                        disableClearable: true,
                        items:
                            structureItem && type
                                ? expressionOperandsByType[type].reduce(
                                      (pv: Record<string, UIModelFieldSelectItem>, cv) => {
                                          pv[cv.value] = {
                                              label: t(`tables:selection.tabs.rules.fields.operand.values.${cv.value}`),
                                          };
                                          return pv;
                                      },
                                      {},
                                  )
                                : {},
                    }}
                    uuid={callingListId + structureItem?.type}
                    fullWidth={true}
                    constrains={'notEmpty'}
                    value={item.operand}
                    disabled={disabled}
                    onChange={(value) => {
                        onChange('operand', value);
                    }}
                    helpId={[...helpPrefix, 'operand']}
                />
            </div>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type:
                            structureItem?.type === DialerStructureItemType.number
                                ? UIModelFieldType.number
                                : UIModelFieldType.text,
                        title: t('tables:selection.tabs.rules.fields.value.title'),
                        field: 'value',
                    }}
                    uuid={callingListId + structureItem?.type}
                    fullWidth={true}
                    constrains={operand?.hasArg ? 'notEmpty' : ''}
                    value={item.value}
                    disabled={disabled || !operand?.hasArg}
                    onChange={(value) => {
                        onChange('value', value);
                        onChange(
                            'value_type',
                            structureItem?.type === DialerStructureItemType.number ? 'number' : 'string',
                        );
                    }}
                    helpId={[...helpPrefix, 'value']}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editor: {
            display: 'flex',
        },
        input: {
            flex: 1,
            marginRight: 5,
        },
    }),
);

export default SelectionRulesV2;
