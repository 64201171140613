import { PermissionTypes } from '@aatdev/common-types';
import { DialerCollections } from '@aatdev/dialer-types';
import { AppointmentModel, ChangeSet } from '@devexpress/dx-react-scheduler';
import { Grid, Paper } from '@material-ui/core';
import i18n from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { doFreeQueryThunk } from '../../../store/actions/DataActions';
import { AppState } from '../../../store/StoreTypes';
import { toTimezonedDate } from '../../../utils/MiscUtils';
import { deleteFromArray, pushField } from '../../../utils/ModelUtils';
import { addValidatedData, createValidateValue, deleteValidatedData } from '../../../utils/ValidatedValueUtils';
import { CommonSchedule } from '../../CommonSchedule/CommonSchedule';

import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import { calendarDataToMap } from '../Utils/WorkCalendarUtils';
import { AppointmentComponent } from './ScheduleComponents/AppointmentComponent';

type FormState = {
    scheduleView: string;
    currentDate?: Date;
};

type CompaignScheduleProps = {
    formState: FormState;
    updateFormState: (field: keyof FormState, value: any) => void;
} & DataFormProps;

const CampaignSchedule: FunctionComponent<CompaignScheduleProps> = (props) => {
    const { onChange, element, fieldPrefix, formState, updateFormState } = props;
    const { data, permissions } = element;
    const tabData: AppointmentModel[] = (data[fieldPrefix] || []).map((e: any) => deleteValidatedData(e));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(doFreeQueryThunk(DialerCollections.work_calendar, DialerCollections.work_calendar, {}));
    }, []);

    /**
     * fix the end date if the repeat is chosen
     */
    const fixAppointmentModel = (model: any) => {
        const startDateTz = toTimezonedDate(model.startDate.value);
        const endDateTz = toTimezonedDate(model.endDate.value);
        const startDate = moment(model.startDate.value);
        const endDate = moment(model.endDate.value);
        if (model.rRule) {
            endDate.set('year', startDate.toDate().getFullYear());
            endDate.set('month', startDate.toDate().getMonth());
            endDate.set('date', startDate.toDate().getDate());
            endDateTz.set('year', startDateTz.toDate().getFullYear());
            endDateTz.set('month', startDateTz.toDate().getMonth());
            endDateTz.set('date', startDateTz.toDate().getDate());
        }
        if (model.allDay?.value) {
            startDate.set('hour', 0);
            startDate.set('minute', 0);
            startDate.set('second', 0);
            startDateTz.set('hour', 0);
            startDateTz.set('minute', 0);
            startDateTz.set('second', 0);
            endDate.set('hour', 23);
            endDate.set('minute', 59);
            endDate.set('second', 59);
            endDateTz.set('hour', 23);
            endDateTz.set('minute', 59);
            endDateTz.set('second', 59);
        }
        model.startDate.value = startDate.toDate();
        model.endDate.value = endDate.toDate();
        model.startDateTz = createValidateValue(startDateTz.toDate(), true);
        model.endDateTz = createValidateValue(endDateTz.toDate(), true);
        return model;
    };

    const commitChanges = (changes: ChangeSet) => {
        onChange((data: any[]) => {
            if (changes.added) {
                return pushField(
                    data || [],
                    [],
                    fixAppointmentModel(
                        addValidatedData({
                            id: v4().toString(),
                            ...(changes.added as AppointmentModel),
                            color: 'red',
                        }),
                    ),
                );
            }
            if (changes.deleted) {
                return deleteFromArray(data || [], changes.deleted);
            }
            if (changes.changed) {
                const result: any[] = [...data];
                Object.keys(changes.changed).forEach((id) => {
                    const index = data.findIndex((e) => e.id?.value === id);
                    if (index > -1) {
                        result[index] = fixAppointmentModel({
                            ...result[index],
                            ...addValidatedData(changes.changed![id]),
                        });
                    }
                });
                return result;
            }
            return data;
        });
    };

    return (
        <Grid container spacing={2}>
            <Paper style={{ width: '100%', height: '100%' }}>
                <CommonSchedule
                    data={tabData}
                    locale={i18n.language}
                    currentDate={formState.currentDate || moment().toDate()}
                    onCurrentDate={(date) => updateFormState('currentDate', date)}
                    onCommitChanges={commitChanges}
                    appointmentComponent={withCalendarData(AppointmentComponent)}
                    editable={permissions[PermissionTypes.canEditSchedule]}
                />
            </Paper>
        </Grid>
    );
};

export const withCalendarData = (Component: any) => {
    return (props: any) => {
        const calendarData =
            useSelector((state: AppState) => state.db.tableData[DialerCollections.work_calendar])?.items || [];
        return <Component {...props} calendarData={calendarDataToMap(calendarData)} />;
    };
};

export default CampaignSchedule;
