export enum CalendarDayType {
    workday = 'workday',
    nowork = 'nowork',
    weekend = 'weekend',
    preholiday = 'preholiday',
    reset = '',
}

export type CalendarDayItemType = {
    type: CalendarDayType;
    isOrig: boolean;
};

export type CalendarDaysByKey = Record<string, CalendarDayItemType>;
