import { ChangeFuncType } from './ExpressionEditorTypes';

type ExpressionRuleProps = {
    value: any;
    renderEditor: ChangeFuncType;
    onChange: (field: (string | number)[], value: any) => void;
    path: (string | number)[];
};

const ExpressionRule: React.FC<ExpressionRuleProps> = (props) => {
    const { renderEditor, value, onChange, path } = props;
    return (
        <div>
            {renderEditor(value, (field, value) => {
                onChange([...path, field], value);
            })}
        </div>
    );
};

export default ExpressionRule;
