import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from '../store/StoreTypes';
import { DataMenuItem } from '../store/actions/DataActionTypes';

export const useCurrentMenuItem = (): DataMenuItem | undefined => {
    const location = useLocation();
    const menuItems = useSelector((state: AppState) => state.db.menuItems);
    const args = location.pathname.split('/');
    return menuItems.find((e) => e.id === args[args.length - 1]);
};
