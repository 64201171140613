import { UIModelFieldSelectItem, UIModelFieldType, ValidatedValue } from '@aatdev/common-types';
import { DialerCampaignLimitOperands, DialerCampaignLimits } from '@aatdev/dialer-types';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { createReduxStateChangeFunc } from '../../../store/actions/DataActionUtils';
import { createValidateValue } from '../../../utils/ValidatedValueUtils';
import { DataFormProps } from '../../DataEditor/DataForm/DataFormTypes';
import DataInput from '../../DataEditor/DataForm/DataInput';
import ExpressionEditor from '../../ExpressionEditor/ExpressionEditor';
import { ExpressionTypeValidated } from '../../ExpressionEditor/ExpressionEditorTypes';

type CompaignLimitsProps = {} & DataFormProps;

type LimitItemValidated = {
    field: ValidatedValue<string>;
    operand: ValidatedValue<string>;
    value: ValidatedValue<{
        type: string;
        value: number;
    }>;
};

const CampaignLimits: React.FC<CompaignLimitsProps> = (props) => {
    const { onChange, element, fieldPrefix } = props;
    const { data, permissions } = element;

    const tabData: ExpressionTypeValidated = data[fieldPrefix];

    useEffect(() => {
        if (!tabData) {
            onChange(() => ({
                id: createValidateValue(v4(), true),
                type: createValidateValue('set', true),
                op: createValidateValue('and', true),
                inner: [],
            }));
        }
    }, []);

    if (!tabData) {
        return <span />;
    }

    const renderEditor = (value: any, onChange: (field: string, value: any) => void) => {
        return <ItemEditor item={value} readonly={!permissions.edit} onChange={onChange} />;
    };

    const handleChange = createReduxStateChangeFunc(onChange);

    return (
        <ExpressionEditor
            renderEditor={renderEditor}
            value={tabData}
            onChange={handleChange}
            readonly={!permissions.edit}
        />
    );
};

type ItemEditorProps = {
    item: LimitItemValidated;
    onChange: (field: string, value: any) => void;
    readonly: boolean;
};

const ItemEditor: FunctionComponent<ItemEditorProps> = (props: ItemEditorProps) => {
    const { item, onChange, readonly } = props;
    const styles = useStyles();
    const { t } = useTranslation();

    return (
        <div className={styles.editor}>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type: UIModelFieldType.select,
                        title: t('tables:campaign.tabs.limits.fields.field.title'),
                        field: 'type',
                        disableClearable: true,
                        items: Object.values(DialerCampaignLimits).reduce(
                            (pv: Record<string, UIModelFieldSelectItem>, cv) => {
                                pv[cv] = {
                                    label: t(`tables:campaign.tabs.limits.fields.field.values.${cv}`),
                                };
                                return pv;
                            },
                            {},
                        ),
                    }}
                    fullWidth={true}
                    constrains={'notEmpty'}
                    value={item.field}
                    disabled={readonly}
                    onChange={(value) => onChange('field', value)}
                />
            </div>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type: UIModelFieldType.select,
                        title: t('tables:campaign.tabs.limits.fields.operand.title'),
                        field: 'type',
                        disableClearable: true,
                        items: Object.values(DialerCampaignLimitOperands).reduce(
                            (pv: Record<string, UIModelFieldSelectItem>, cv) => {
                                pv[cv] = {
                                    label: t(`tables:campaign.tabs.limits.fields.operand.values.${cv}`),
                                };
                                return pv;
                            },
                            {},
                        ),
                    }}
                    fullWidth={true}
                    constrains={'notEmpty'}
                    value={item.operand}
                    disabled={readonly}
                    onChange={(value) => onChange('operand', value)}
                />
            </div>
            <div className={styles.input}>
                <DataInput
                    config={{
                        type: UIModelFieldType.int,
                        title: t('tables:campaign.tabs.limits.fields.value.title'),
                        field: 'value',
                    }}
                    fullWidth={true}
                    constrains={'notEmpty'}
                    value={item.value}
                    disabled={readonly}
                    onChange={(value) => {
                        onChange('value', value);
                        onChange('value_type', 'number');
                    }}
                />
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        editor: {
            display: 'flex',
        },
        input: {
            flex: 1,
            marginRight: 5,
        },
    }),
);

export default CampaignLimits;
