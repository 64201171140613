import {
    AppointmentModel,
    Appointments as AppointmentsBase,
    ChangeSet,
    EditingState,
    ViewState,
} from '@devexpress/dx-react-scheduler';
import {
    AppointmentForm,
    Appointments,
    AppointmentTooltip,
    ConfirmationDialog,
    DateNavigator,
    DragDropProvider,
    EditRecurrenceMenu,
    MonthView,
    Scheduler,
    Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { v4 } from 'uuid';
import FormOverlay from './FormOverlay';
import { ScheduleBasicLayout } from './ScheduleBasicLayout';
import { appointmentFormMessages, confirmationDialogMessages, editRecurrentMenuMessages } from './ScheduleI18n';
import SchedulerDateEditor from './SchedulerDateEditor';
import { ScheduleTextEditor } from './ScheduleTextEditor';

type Props = {
    locale: string;
    data: AppointmentModel[] | undefined;
    currentDate: Date;
    onCurrentDate: (date: Date) => void;
    onCommitChanges: (changes: ChangeSet) => void;
    editable: boolean;
    appointmentComponent?: React.ComponentType<AppointmentsBase.AppointmentProps>;
};

/**
 * see https://devexpress.github.io/devextreme-reactive/react/scheduler/docs/guides/appointments/#recurring-appointments
 * see https://devexpress.github.io/devextreme-reactive/react/scheduler/demos/featured/appearance-customization/
 * see https://devexpress.github.io/devextreme-reactive/react/scheduler/docs/guides/editing/
 * see https://codesandbox.io/s/5v26o?file=/demo.js:1881-1899
 * @param props
 * @constructor
 */
export const CommonSchedule: FunctionComponent<Props> = (props: Props) => {
    const { data, locale, currentDate, onCurrentDate, onCommitChanges, appointmentComponent, editable } = props;
    return (
        <Scheduler data={data} height={'auto'} locale={locale} firstDayOfWeek={1}>
            <ViewState currentDate={currentDate} onCurrentDateChange={(date) => onCurrentDate(date)} />
            <EditingState
                onCommitChanges={onCommitChanges}
                onAddedAppointmentChange={(o: any) => {
                    /* disable allDay by default */
                    if (!o.id) {
                        o.id = v4();
                        o.allDay = false;
                        const sd = moment(o.startDate);
                        sd.set('hour', 9);
                        sd.set('minute', 0);
                        sd.set('second', 0);
                        const ed = moment(o.startDate);
                        ed.set('hour', 18);
                        ed.set('minute', 0);
                        ed.set('second', 0);
                        o.startDate = sd.toDate();
                        o.endDate = ed.toDate();
                    }
                }}
            />
            {editable && <EditRecurrenceMenu messages={editRecurrentMenuMessages()} />}
            <ConfirmationDialog messages={confirmationDialogMessages()} />
            <MonthView name={'month'} displayName={''} />
            {appointmentComponent ? <Appointments appointmentComponent={appointmentComponent} /> : <Appointments />}
            <AppointmentTooltip showOpenButton showCloseButton showDeleteButton={editable} />
            <AppointmentForm
                readOnly={!editable}
                messages={appointmentFormMessages()}
                basicLayoutComponent={ScheduleBasicLayout}
                textEditorComponent={ScheduleTextEditor}
                dateEditorComponent={SchedulerDateEditor}
                overlayComponent={FormOverlay}
            />
            <Toolbar />
            <DateNavigator />
            <DragDropProvider />
        </Scheduler>
    );
};
